import React from "react";
import {
  ProFormDateTimeRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config.ts";
import { Department, Position } from "@/models";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import axios from "@/axios";
import { useTranslation } from "react-i18next";

const CompanyPersonnelChangesFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_full_name"}
      />
      <ProFormSelect
        name={"old_department"}
        colProps={{ span: 8 }}
        label={t("Старое подразделение")}
        showSearch
        debounceTime={500}
        request={async (params, props) => {
          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/departments/search",
            ...axiosConfigAdapter({
              pageSize: 10,
            }),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          return axios
            .request<OrionRestIndexResponse<Department>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((department) => {
                data.push({
                  label: department.name,
                  value: department.name,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/departments/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.id,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
      />
      <ProFormSelect
        name={"old_position"}
        showSearch
        colProps={{ span: 8 }}
        label={t("Старая должность")}
        debounceTime={500}
        request={async (params, props) => {
          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/positions/search",
            ...axiosConfigAdapter({
              pageSize: 10,
            }),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          return axios
            .request<OrionRestIndexResponse<Position>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((position) => {
                data.push({
                  label: position.name,
                  value: position.name,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/positions/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.name,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
      />
      <ProFormSelect
        name={"new_department"}
        colProps={{ span: 8 }}
        label={t("Новое подразделение")}
        showSearch
        debounceTime={500}
        request={async (params, props) => {
          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/departments/search",
            ...axiosConfigAdapter({
              pageSize: 10,
            }),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          return axios
            .request<OrionRestIndexResponse<Department>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((department) => {
                data.push({
                  label: department.name,
                  value: department.name,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/departments/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.id,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
      />
      <ProFormSelect
        name={"new_position"}
        showSearch
        colProps={{ span: 8 }}
        label={t("Новая должность")}
        debounceTime={500}
        request={async (params, props) => {
          const axiosConfig: AxiosRequestConfig = {
            method: "POST",
            url: "/api/positions/search",
            ...axiosConfigAdapter({
              pageSize: 10,
            }),
          };

          axiosConfig.data.filters.push({
            field: "name",
            operator: "ilike",
            value: `%${params?.keyWords ?? ""}%`,
          });

          return axios
            .request<OrionRestIndexResponse<Position>>(axiosConfig)
            .then(async (res) => {
              const data: any = [];

              res.data.data.forEach((position) => {
                data.push({
                  label: position.name,
                  value: position.name,
                });
              });

              if (
                props?.fieldProps.value &&
                !data.some((obj: any) => obj.value === props.fieldProps.value)
              ) {
                const currentData = await axios
                  .get(`/api/positions/${props.fieldProps.value}`)
                  .then(({ data }) => ({
                    label: data.data.name,
                    value: data.data.name,
                  }));
                data.unshift(currentData);
              }

              return data;
            });
        }}
      />
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="Изменивший информацию"
        name={["user_id"]}
      />
      <ProFormDateTimeRangePicker
        label="Дата изменения"
        name={["range", "updated_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default CompanyPersonnelChangesFormFields;
