import React from "react";
import { ProFormDateTimeRangePicker } from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import MemberDepartmentInput from "@/entities/member/ui/department-input";
import MemberPositionInput from "@/entities/member/ui/position-input";
import CourseSearchSelect from "@/entities/course/ui/select";
import { useTranslation } from "react-i18next";

const RepeatedEducationFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <MemberDepartmentInput
        label={t("Подразделение")}
        colProps={{ span: 8 }}
        name={["member", "org_structure_assignments", "department_id"]}
        mode="multiple"
        hideAddInput
      />
      <MemberPositionInput
        label={t("Должность")}
        name={["member", "org_structure_assignments", "position_id"]}
        colProps={{ span: 8 }}
        mode="multiple"
        hideAddInput
      />
      <CourseSearchSelect
        mode="multiple"
        label="Курс"
        name={["pool", "course", "id"]}
        colProps={{ span: 8 }}
      />
      <ProFormDateTimeRangePicker
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
      <ProFormDateTimeRangePicker
        label="Дата прохождения повторного обучения"
        name={["scopes", "whereReEducationAtBetween"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default RepeatedEducationFormFields;
