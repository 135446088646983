import React, { useState } from "react";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import {
  Course,
  Department,
  Member,
  Pool,
  PoolParticipant,
  Position,
} from "@/models";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { AxiosRequestConfig } from "axios";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ArchivalCourseIcon from "@/entities/course/ui/archival-icon";

type Data = Omit<PoolParticipant, "pool" | "member"> & {
  pool: Omit<Pool, "course"> & {
    course: Course;
  };
  member: Member;
  filters: any[];
  scopes: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const RepeatedEducationTable: React.FC<TableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const request: ProTableProps<Data, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const { re_education_at, retry_month_count, ...rest } = sort;
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/pool-participants/search",
      ...axiosConfigAdapter(params, rest, filter),
    };

    if (re_education_at) {
      config.data.scopes.push({
        name: "orderByReEducationAt",
        parameters: re_education_at === "ascend" ? ["ASC"] : ["DESC"],
      });
    }

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    if (params.scopes) {
      config.data.scopes.push(...params.scopes);
    }

    config.data.filters.push({
      field: "ended_at",
      operator: "!=",
      value: null,
    });

    config.data.scopes.push({
      name: "withReEducationAt",
    });

    config.data.scopes.push({
      name: "withoutNullReEducationAt",
    });

    if (retry_month_count) {
      config.data.scopes.push({
        name: "withRetryMonthCount",
        parameters: retry_month_count === "ascend" ? ["ASC"] : ["DESC"],
      });
    } else {
      config.data.scopes.push({
        name: "withRetryMonthCount",
      });
    }

    config.data.includes.push(
      { relation: "member" },
      { relation: "pool" },
      { relation: "pool.course" },
      { relation: "member.org_structure_assignments.position" },
      { relation: "member.org_structure_assignments.department" },
    );

    return axios
      .request<OrionRestIndexResponse<Data>>(config)
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    toolBarRender: false,
    request,
    pagination: {
      defaultPageSize: 20,
    },
    style: { width: "100%" },
    size: "small",
    columns: [
      {
        title: "Курс",
        dataIndex: ["pool", "course", "id"],
        renderText: (_, record) => {
          return (
            <>
              {record.pool.course.name}
              {record.pool.course.status === "archival" && (
                <ArchivalCourseIcon />
              )}
            </>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/courses/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Course>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },

      {
        title: "Поток",
        dataIndex: ["pool", "id"],
        renderText: (_, record) => {
          return <>{record.pool.name}</>;
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/pools/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Pool>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((pool) => ({
                  label: pool.name,
                  value: pool.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
        filters: true,
        filterMode: "search",
        sorter: true,
      },
      {
        title: "Фактическая дата завершения",
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
      {
        title: t("Подразделение"),
        dataIndex: ["member", "org_structure_assignments", "department_id"],
        render: (_, { member: { org_structure_assignments } }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-department`}>
                  {el.department?.name ? el.department?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/departments/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Department>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: t("Должность"),
        dataIndex: ["member", "org_structure_assignments", "position_id"],
        render: (_, { member: { org_structure_assignments } }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-position`}>
                  {el.position?.name ? el.position?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
      },
      {
        title: "Дата прохождения повторного обучения",
        dataIndex: "re_education_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
      },
    ],
  };

  if (error) throw error;

  return (
    <ProTable<Data>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default RepeatedEducationTable;
