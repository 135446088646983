import {
  ProFormDateTimeRangePicker,
  ProFormDigitRange,
  ProFormSelect,
} from "@ant-design/pro-components";
import CourseSearchSelect from "@/entities/course/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import statuses from "@/entities/pool-participant/lib/statuses";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

const IndividualFormFields: React.FC = ({}) => {
  return (
    <>
      <ProFormDateTimeRangePicker
        label="Период"
        colProps={{ span: 8 }}
        name={["range", "starts_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        normalize={normalizeDateRange}
      />
      <CourseSearchSelect
        mode="multiple"
        label="Курс"
        name={["pool", "course", "id"]}
        colProps={{ span: 8 }}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <ProFormSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="Статус в потоке"
        name="status"
        valueEnum={statuses}
      />
      <ProFormDigitRange
        label="Прогресс прохождения"
        colProps={{ span: 8 }}
        name={["range", "progress_percent"]}
        fieldProps={{
          max: 100,
          min: 0,
        }}
        separator="-"
        placeholder={["от", "до"]}
      />
      <ProFormDateTimeRangePicker
        label="Фактическая дата начала"
        name={["range", "started_at"]}
        colProps={{ span: 8 }}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        normalize={normalizeDateRange}
      />
      <ProFormDateTimeRangePicker
        label="Планируемая дата завершения"
        name={["range", "ends_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
      <ProFormDateTimeRangePicker
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default IndividualFormFields;
