import React, { useEffect } from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import ModeContext from "@/entities/mode/lib/context";
import { Mode } from "@/entities/mode/lib/type";
import ErrorPage from "@/shared/pages/error.tsx";
import * as Sentry from "@sentry/react";

type Props = {
  children: React.ReactNode;
};

const ModeContextProvider: React.FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useSWR(
    "/api/.well-known/mode",
    (url) =>
      axios
        .get<{ value: Mode }>(url)
        .then((res) => res.data.value),
    { revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  useEffect(() => {
    Sentry.setTag("mode", data);
  }, [data]);

  if (isLoading) return <Spin />;
  if (error) return <ErrorPage errorMessage={error.message} />;
  if (!data) return <ErrorPage errorMessage={"Данные отсутствуют"} />;

  return <ModeContext.Provider value={data}>{children}</ModeContext.Provider>;
};

export default ModeContextProvider;
