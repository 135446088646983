import React from "react";
import { Flex, Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import GroupParticipantTable from "@/entities/group-participant/ui/table";
import { Link, useParams } from "react-router-dom";
import useMe from "@/entities/me/lib/use";

const Page: React.FC = () => {
  const { group_id } = useParams();
  const member = useMe();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <GroupParticipantTable
        hasQueryParams
        options={{
          setting: {
            draggable: false,
            showListItemOption: false,
          },
          reload: false,
          density: false,
        }}
        columnsState={{
          persistenceKey: `group-participant-table`,
        }}
        groupId={Number(group_id)}
        columns={[
          {
            dataIndex: ["member", "full_name"],
            render: (node, { member_id }) => {
              if (member.permissions.includes("member:view")) {
                return <Link to={`/manage/members/${member_id}`}>{node}</Link>;
              }
              return node;
            },
            disable: true,
            hideInSetting: true,
          },
          {
            dataIndex: ["member", "org_structure_assignments", "department_id"],
          },
          {
            dataIndex: ["member", "org_structure_assignments", "position_id"],
          },
          {
            dataIndex: "created_at",
          },
        ]}
      />
    </Flex>
  );
};

export default Page;
