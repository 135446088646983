import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import PoolTemplateTable from "@/entities/pool-template/ui/table";
import ArchivalCourseIcon from "@/entities/course/ui/archival-icon";

const Page: React.FC = () => {
  return (
    <Flex gap={8} vertical style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <PoolTemplateTable
        hasQueryParams
        options={{
          setting: {
            draggable: false,
            showListItemOption: false,
          },
          reload: false,
          density: false,
        }}
        columnsState={{
          persistenceKey: "pool-templates-table",
        }}
        columns={[
          {
            dataIndex: "course_id",
            render: (dom, { course_id, course }) => (
              <Link to={`/manage/courses/${course_id}`}>
                {dom} {course?.status === "archival" && <ArchivalCourseIcon />}
              </Link>
            ),
            hideInSetting: true,
            disable: true,
          },
          { dataIndex: "name" },
          { dataIndex: "created_at" },
        ]}
      />
    </Flex>
  );
};

export default Page;
