import React from "react";
import { Automation } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { AxiosRequestConfig } from "axios";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { statusValueEnum } from "@/entities/automation/lib/status-value-enum";
import { Button, Grid } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AutomationDeleteButton from "@/entities/automation/ui/delete-button";
import AutomationStatusToogleButton from "@/entities/automation/ui/status-toogle-button";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import useTriggerValueEnum from "@/entities/automation/lib/use-trigger-value-enum.tsx";
import {
  ProFormDateTimeRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

type Data = Automation;

type Params = Partial<Automation>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<Automation>;
};

const AutomationTable: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const triggerValueEnum = useTriggerValueEnum();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const toolBarRender: TableProps["toolBarRender"] = (
    action,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
    if (!action) throw new Error("action is undefined");

    const toUpdateRecordKey =
      selectedRowKeys.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;

    return [
      member.permissions.includes("automation:delete") && (
        <AutomationDeleteButton
          key={"delete"}
          recordKeys={selectedRowKeys.map(Number)}
          onAfterDelete={() => {
            action.reload();
            action.clearSelected?.();
          }}
        />
      ),
      member.permissions.includes("automation:update") && (
        <AutomationStatusToogleButton
          key={"disabled"}
          status="disabled"
          recordKeys={selectedRowKeys.map(Number)}
          onAfterFinish={() => action.reload()}
        />
      ),
      member.permissions.includes("automation:update") && (
        <AutomationStatusToogleButton
          key={"enabled"}
          status="enabled"
          recordKeys={selectedRowKeys.map(Number)}
          onAfterFinish={() => action.reload()}
        />
      ),
      member.permissions.includes("automation:update") && (
        <Button
          key={"update"}
          type="primary"
          onClick={() => navigate(`/manage/automations/${toUpdateRecordKey}`)}
          disabled={!toUpdateRecordKey}
        >
          Редактировать
        </Button>
      ),
      member.permissions.includes("automation:create") && (
        <Button
          key={"create"}
          type="primary"
          onClick={() => navigate("/manage/automations/create")}
        >
          Создать
        </Button>
      ),
    ];
  };
  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/automations/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const data = await axios
      .request<OrionRestIndexResponse<Automation>>(actionConfig)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {},
    columnsState: {
      persistenceKey: "automation-table",
    },
    options: {
      setting: {
        draggable: false,
        showListItemOption: false,
      },
      reload: false,
      density: false,
    },
    pagination: { showSizeChanger: true },
    hasQueryParams: true,
    request,
    style: {
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
    },
    scroll: { y: parentHeight - (screens.xl ? 152 : 172) },
    toolBarRender,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        hideInSetting: true,
        disable: true,
        render: (dom, { id: automation_id }) => {
          if (member.permissions.includes("automation:view")) {
            return (
              <Link to={`/manage/automations/${automation_id}`}>{dom}</Link>
            );
          }
          return dom;
        },
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueEnum: statusValueEnum,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Условие назначения обучения",
        tooltip: true,
        dataIndex: "trigger",
        valueEnum: triggerValueEnum,
        formItemProps: {
          tooltip: false,
        },
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Дата создания",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        sorter: true,
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "created_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <ProTable<Data, Params> {...defaultProps} />
    </div>
  );
};

export default AutomationTable;
