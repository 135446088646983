import React from "react";
import { Flex, Space } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import AutomationTable from "@/entities/automation/ui/table";

const Page: React.FC = () => {
  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <AutomationTable />
    </Flex>
  );
};

export default Page;
