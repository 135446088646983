import React, { useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Spin, theme } from "antd";
import NotificationList from "@/entities/notification/ui/list";
import useSWR from "swr";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useMe from "@/entities/me/lib/use";

const NotificationDrawer: React.FC = () => {
  const member = useMe();
  const { token } = theme.useToken();
  const {
    data: badgeCount,
    isLoading,
    error,
    mutate,
  } = useSWR(
    "/api/notifications/search",
    (url) =>
      axios
        .post<OrionRestIndexResponse<Notification>>(url, {
          filters: [
            {
              field: "is_read",
              operator: "=",
              value: false,
            },
            {
              field: "member_id",
              operator: "=",
              value: member.id,
            },
          ],
        })
        .then((res) => res.data.meta.total),
    { refreshInterval: 1000 * 30 },
  );

  const mutateBangeCount = () => {
    mutate();
  };

  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (badgeCount === undefined) throw new Error("No data");

  return (
    <>
      <Badge count={badgeCount}>
        <Button
          type={"text"}
          onClick={showDrawer}
          icon={<BellOutlined style={{ fontSize: token.fontSizeXL }} />}
        />
      </Badge>
      <Drawer title={"Список уведомлений"} onClose={onClose} open={open}>
        <NotificationList onCheckMarked={mutateBangeCount} />
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
