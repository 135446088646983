import React from "react";
import {
  ProForm,
  ProFormDigit,
  ProFormGroup,
  ProFormProps,
  ProFormText,
} from "@ant-design/pro-components";
import { Space } from "@/models";
import {
  Button,
  Col,
  Flex,
  message,
  Modal,
  Row,
  Space as AntdSpace,
  Statistic,
  theme,
  Typography,
} from "antd";
import axios from "@/axios";
import { OrionRestUpdateResponse } from "@/shared/types/orion-rest";
import styled from "styled-components";
import { authenticationTypeEnum } from "@/entities/space/lib/model";
import useSpace from "@/entities/space/lib/use";
import ProFormPhone from "@/shared/ant-design-pro-components/form-phone/ui/component.tsx";
import useMode from "@/entities/mode/lib/use";
import { CopyOutlined } from "@ant-design/icons";
import PageContainer from "@/shared/ant-design/page-container/ui";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useMe from "@/entities/me/lib/use";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const FormWrapper = styled.div`
  .space-form .ant-space {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .space-form {
    height: 100%;
  }
  height: 100%;
  .space-form .ant-space .ant-space-item {
    width: 100%;
  }
`;

const Page: React.FC = () => {
  const space = useSpace();
  const [modal, modalHolder] = Modal.useModal();
  const [form] = ProForm.useForm<Space>();
  const {
    token: { sizeSM, colorBgContainer, sizeMD, borderRadius },
  } = theme.useToken();
  const mode = useMode();
  const member = useMe();

  const request: ProFormProps<Space>["request"] = async () => {
    const space_id = await axios
      .get("/api/.well-known/space")
      .then((res) => res.data.id);

    return axios
      .get(`/api/spaces/${space_id}`)
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        throw reason;
      });
  };

  const onSave: ProFormProps<Space>["onFinish"] = async (values) => {
    const touchedValues = form.getFieldsValue(
      true,
      (meta) => meta.touched,
    ) as Space;

    if (touchedValues.slug) {
      const isConfirmed = await modal.confirm({
        title: "Изменение адреса пространства",
        content: (
          <AntdSpace direction={"vertical"}>
            <Typography.Text>
              Вы изменяете адрес пространства, по старому адресу пространство
              будет не доступно.
            </Typography.Text>
            <Typography.Text type={"danger"}>
              Вы уверены, что хотите изменить адрес пространства?
            </Typography.Text>
          </AntdSpace>
        ),
        okText: "Да, я уверен",
        okButtonProps: { danger: true },
        cancelText: "Нет",
      });

      if (!isConfirmed) return;
    }

    const updatedValues = await axios
      .put<OrionRestUpdateResponse<Space>>(
        `/api/spaces/${form.getFieldsValue(true).id}`,
        values,
      )
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        if (reason.response.status === 422) {
          setValidationErrorsToFormFields(form, reason.response.data.errors);
        } else {
          console.error(reason);
        }
        throw reason;
      });

    message.success("Изменения успешно сохранены");

    if (touchedValues.slug) {
      const onOk = () => {
        window.location.replace(updatedValues.url!);
      };

      modal.confirm({
        title: "Перенаправление на новый адрес пространства",
        content: (
          <>
            <Typography.Text>
              Вы изменили адрес пространства, поэтому необходимо перейти на
              него.
            </Typography.Text>
            <Statistic.Countdown
              value={Date.now() + 10 * 1000}
              onFinish={onOk}
            />
            <Typography.Text type={"secondary"}>
              Если этого не произошло в течение 10 секунд, нажмите на кнопку
              "Перейти"
            </Typography.Text>
          </>
        ),
        onOk,
        okText: "Перейти",
        closable: false,
        cancelButtonProps: { style: { display: "none" } },
      });
    } else {
      space.refresh();
    }

    // TODO: Fix this "any" type
    form.setFieldsValue(updatedValues as any);
  };

  const onDelete = () => {
    message.error("В текущий момент невозможно удалить окружение");
    throw new Error("Not implemented");
  };

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      {modalHolder}
      <PageContainer
        style={{
          padding: 0,
          height: "calc(100% - 30px)",
        }}
      >
        <FormWrapper>
          <ProForm<Space>
            className={"space-form"}
            submitter={false}
            form={form}
            disabled={!member.permissions.includes("space:update")}
            onFinish={onSave}
            request={request}
          >
            <AntdSpace
              direction={"vertical"}
              style={{
                padding: sizeMD,
                background: colorBgContainer,
                borderRadius,
              }}
            >
              <Flex justify={"end"} gap={16}>
                <Button danger type="primary" onClick={onDelete}>
                  Удалить
                </Button>
                {member.permissions.includes("space:update") && (
                  <Button type={"primary"} onClick={form.submit}>
                    Сохранить
                  </Button>
                )}
              </Flex>

              <Row gutter={16}>
                <Col span={12}>
                  <ProFormGroup>
                    <ProFormText
                      name={"uuid"}
                      label={"UUID"}
                      rules={[{ required: true }]}
                      disabled
                      hidden={mode === "cloud"}
                      fieldProps={{
                        suffix: (
                          <CopyOutlined
                            onClick={() => {
                              navigator.clipboard.writeText(
                                form.getFieldValue("uuid"),
                              );
                              message.success("UUID скопирован");
                            }}
                          />
                        ),
                      }}
                    />
                    <ProFormText
                      name={"company_name"}
                      label={"Название компании"}
                      rules={[{ required: true, max: 255 }]}
                    />
                    <ProFormText
                      name={"slug"}
                      label={"Адрес пространства"}
                      rules={[
                        { required: true },
                        { min: 3, max: 255 },
                        {
                          pattern: /^[a-z0-9-]*$/i,
                          message:
                            "Поддерживаются только латинские буквы в нижнем регистре, цифры и тире",
                        },
                      ]}
                      fieldProps={{
                        suffix: `.${window.location.host
                          .split(".")
                          .slice(1)
                          .join(".")}`,
                      }}
                      tooltip={
                        "Адрес пространства - это доменное имя, по которому вы сможете войти в пространство"
                      }
                      hidden={mode === "self-hosted"}
                    />
                    <ProFormDigit
                      name={"company_employees_count"}
                      label={"Количество сотрудников"}
                      min={0}
                      rules={[{ required: true }]}
                    />
                    <ProFormText
                      name="company_email"
                      label="Почта"
                      rules={[{ type: "email", max: 255 }]}
                    />
                    <ProFormPhone name="company_phone_number" label="Телефон" />

                    <ProFormText
                      name={"company_legal_address"}
                      label={"Юридический адрес"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText name={"company_ogrn"} label={"ОГРН"} />
                    <ProFormText name={"company_kpp"} label={"КПП"} />
                    <ProFormText name={"company_inn"} label={"ИНН"} />
                    <ProFormText
                      name={"company_head_position"}
                      label={"Должность руководителя предприятия"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText
                      name={"company_head_full_name"}
                      label={"ФИО руководителя предприятия"}
                      rules={[{ max: 255 }]}
                    />
                  </ProFormGroup>
                </Col>

                <Col span={12}>
                  <ProFormGroup title={"Банковские реквизиты"}>
                    <ProFormText
                      name={"company_bank_account_number"}
                      label={"Номер расчетного счета"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText
                      name={"company_bank_legal_address"}
                      label={"Юридический адрес банка"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText
                      name={"company_bank_correspondent_account"}
                      label={"Корреспондентский счет банка"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText
                      name={"company_bank_inn"}
                      label={"ИНН банка"}
                      rules={[{ max: 255 }]}
                    />
                    <ProFormText
                      name={"company_bank_bik"}
                      label={"БИК банка"}
                      rules={[{ max: 255 }]}
                    />
                  </ProFormGroup>

                  <ProFormGroup title={"Контактное лицо"}>
                    <ProFormText
                      name={"contact_full_name"}
                      label={"ФИО"}
                      rules={[{ required: true, max: 255 }]}
                    />
                    <ProFormPhone
                      name="contact_phone_number"
                      label="Телефон"
                      rules={[{ required: true }]}
                    />
                    <ProFormText
                      name={"contact_email"}
                      label={"Почта"}
                      rules={[{ required: true, max: 255 }, { type: "email" }]}
                    />
                  </ProFormGroup>
                </Col>
              </Row>
            </AntdSpace>
          </ProForm>
        </FormWrapper>
      </PageContainer>
    </Flex>
  );
};

export default Page;
