import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorPage from "./error";
import * as Sentry from "@sentry/react";

const ErrorRouter: React.FC = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  return (
    <ErrorPage
      errorMessage={error instanceof Error ? error.message : undefined}
    />
  );
};

export default ErrorRouter;
