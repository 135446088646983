import React from "react";
import { Outlet } from "react-router-dom";
import VersionContextProvider from "@/entities/version/lib/context-provider.tsx";
import ModeContextProvider from "@/entities/mode/lib/context-provider.tsx";
import FeaturesContextProvider from "@/entities/features/lib/context-provider.tsx";
import SyncUserFromAuthToSentry from "@/shared/contexts/sync-user-from-auth-to-sentry.tsx";
import ContextAuth from "@/pages/context-auth.tsx";

const Context: React.FC = () => {
  return (
    <VersionContextProvider>
      <ModeContextProvider>
        <FeaturesContextProvider>
          <ContextAuth>
            <SyncUserFromAuthToSentry>
              <Outlet />
            </SyncUserFromAuthToSentry>
          </ContextAuth>
        </FeaturesContextProvider>
      </ModeContextProvider>
    </VersionContextProvider>
  );
};

export default Context;
