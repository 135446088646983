import { ProListProps } from "@ant-design/pro-components";
import {
  Alert,
  Button,
  message,
  Space,
  Spin,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { AxiosRequestConfig } from "axios";
import useSWRInfinite from "swr/infinite";
import { Member, Notification } from "@/models";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import React, { useEffect } from "react";
import useMe from "@/entities/me/lib/use";
import { CheckCircleTwoTone } from "@ant-design/icons";
import dayjs from "dayjs";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";

type NotificationListProps = ProListProps & { onCheckMarked: () => void };

const NotificationList: React.FC<NotificationListProps> = ({ ...props }) => {
  const member = useMe();
  const { token } = theme.useToken();

  const { data, mutate, size, setSize, isLoading, error } = useSWRInfinite(
    (pageIndex: number, previousPageData: Member[]) => {
      if (previousPageData && !previousPageData.length) return null;
      return [`/api/notifications/search`, pageIndex + 1];
    },
    async ([url, current]) => {
      const config: AxiosRequestConfig = {
        method: "POST",
        url,
        ...axiosConfigAdapter({ current, pageSize: 10 }),
      };

      config.data.filters.push({
        field: "member_id",
        operator: "=",
        value: member.id,
      });

      config.data.sort.push({
        field: "created_at",
        direction: "desc",
      });

      return axios
        .request<OrionRestIndexResponse<Notification>>(config)
        .then((res) => {
          return res.data.data.map((member) => ({
            ...member,
            meta: res.data.meta,
          }));
        });
    },
    {
      revalidateFirstPage: false,
      refreshInterval: 60 * 1000 * 5,
    },
  );

  const onRead = async (record: Notification) => {
    await axios
      .put(`/api/notifications/${record.id}`, { ...record, is_read: true })
      .then(() => {
        props.onCheckMarked();
        mutate();
      })
      .catch((error) => {
        message.error(error.response.data.message ?? "Что-то пошло не так");
        throw error;
      });
  };

  useEffect(() => {
    mutate();
  }, []);

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("No data");

  const records = data.flat();

  return (
    <>
      <Space
        direction={"vertical"}
        style={{
          width: "100%",
        }}
      >
        {records.length === 0 && (
          <Typography.Text>Уведомлений нет</Typography.Text>
        )}
        {records.map((notification) => (
          <Alert
            key={notification.id}
            message={notification.text}
            description={
              <Typography.Text
                style={{
                  fontSize: token.fontSizeSM,
                  color: token.colorTextSecondary,
                }}
              >
                {dayjs(notification.created_at).format(dateSTime)}
              </Typography.Text>
            }
            style={{
              padding: token.paddingSM,
              backgroundColor: notification.is_read
                ? undefined
                : token.colorBorder,
            }}
            action={
              !notification.is_read && (
                <Tooltip title={"Отметить как прочитанное"}>
                  <Typography.Link onClick={() => onRead(notification)}>
                    <CheckCircleTwoTone twoToneColor={token.colorBorder} />
                  </Typography.Link>
                </Tooltip>
              )
            }
          />
        ))}
        {records.length > 0 && records.length !== records[0].meta.total && (
          <Button
            type="link"
            onClick={() => {
              console.log(data);
              setSize(size + 1);
            }}
            style={{ width: "100%" }}
          >
            Загрузить еще
          </Button>
        )}
      </Space>
    </>
  );
};
export default NotificationList;
