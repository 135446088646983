import React, { useState } from "react";
import { Department, Member, Position } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { Space, Typography } from "antd";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { useTranslation } from "react-i18next";

type Data = Member & {
  filters?: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const LatestAddedMembersTable: React.FC<TableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const request: ProTableProps<Member, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/members/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    config.data.includes.push(
      { relation: "org_structure_assignments" },
      { relation: "org_structure_assignments.position" },
      { relation: "org_structure_assignments.department" },
    );

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    return axios
      .request<OrionRestIndexResponse<Member>>(config)
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    options: false,
    toolBarRender: false,
    pagination: {
      defaultPageSize: 10,
    },
    request: request,
    style: { width: "100%" },
    size: "small",
    columns: [
      {
        title: "ФИО",
        dataIndex: "full_name",
        sorter: true,
      },
      {
        title: t("Подразделение"),
        dataIndex: ["org_structure_assignments", "department_id"],
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/departments/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Department>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
        render: (_, { org_structure_assignments }) => {
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-department`}>
                  {el.department?.name ? el.department?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
      },
      {
        title: t("Должность"),
        dataIndex: ["org_structure_assignments", "position_id"],
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
        render: (_, { org_structure_assignments }) => {
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-position`}>
                  {el.position?.name ? el.position?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
      },
      {
        title: "Дата добавления",
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  if (error) throw error;

  return (
    <>
      <ProTable<Member>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </>
  );
};

export default LatestAddedMembersTable;
