import { Avatar, Skeleton } from "antd";
import { error } from "console";
import React, { useEffect, useState } from "react";

type AvatarWithLoaderProps = {
  imageUrl: string;
};

const AvatarWithLoader: React.FC<AvatarWithLoaderProps> = ({ imageUrl }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setLoading(false);
    img.onerror = function () {
      setLoading(false);
      setError(true);
    };
  }, [imageUrl]);

  if (loading) {
    return (
      <Skeleton.Avatar
        style={{ display: "flex", alignItems: "center" }}
        active
        size={"large"}
      />
    );
  }
  if (error) {
    return <Avatar size={"large"}>error</Avatar>;
  }

  return <Avatar size={"large"} src={imageUrl} alt="avatar" />;
};

export default AvatarWithLoader;
