import React from "react";
import { Course, Pool } from "@/models";
import axios from "@/axios";
import useSWR from "swr";
import {
  OrionRestIndexResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import PoolRemoveButton from "@/entities/pool/ui/button-remove";
import PoolStartButton from "@/entities/pool/ui/button-start";
import PoolCompleteButton from "@/entities/pool/ui/button-complete";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ProTable, {
  ProTableProps as BaseProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { Grid, message, Spin, Tag } from "antd";
import { RestCallbacks } from "@/shared/rest/lib/types";
import Button from "@/shared/ant-design/button/ui/button";
import { useSearchParams } from "react-router-dom";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import useFeatures from "@/entities/features/lib/use.ts";
import PoolModalForm from "@/entities/pool/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { AxiosRequestConfig } from "axios";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import {
  ProFormDateTimeRangePicker,
  ProFormDigitRange,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

type Record = Pool;

type Params = Partial<Pool>;

type TableProps = BaseProTableProps<Record, Params>;

type PoolTableProps = TableProps & {
  courseId?: Course["id"];
  rest?: RestCallbacks<Record>;
};

const PoolTable: React.FC<PoolTableProps> = ({
  courseId,
  rest = {},
  ...props
}) => {
  const {
    data: course,
    error,
    isLoading,
  } = useSWR(courseId ? `/api/courses/${courseId}` : null, async (url) => {
    return axios
      .get<OrionRestShowResponse<Course>>(url)
      .then((res) => res.data.data);
  });

  const [, setUrlParams] = useSearchParams();
  const actionRef = React.useRef<ActionType>();
  const features = useFeatures();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  if (error) throw error;
  if (isLoading) return <Spin />;

  const isArchivedCourse = (status: Course["status"]) => {
    if (status === "archival") {
      message.error(
        "Данное действие недоступно для потока со статусом Архивный",
      );
      return true;
    }
    return false;
  };

  const request: TableProps["request"] = async (params, sort, filter) => {
    const config = {
      method: "POST",
      url: "/api/pools/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    if (courseId) {
      config.data.filters.push({
        field: "course_id",
        operator: "=",
        value: courseId,
      });
    }

    config.data.includes.push({ relation: "course" });

    const data = await axios
      .request<OrionRestIndexResponse<Pool>>(config)
      .then((res) => res.data);

    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  /** Create form */

  const defaultProps: TableProps = {
    rowKey: "id",
    actionRef,
    request,
    options: false,
    style: {
      width: "calc(100vw - 200px - 32px)",
      maxWidth: "calc(1920px - 200px - 32px)",
    },
    scroll: {
      y: parentHeight - (!screens.xl ? 212 : 192),
    },
    toolBarRender: (_, { selectedRowKeys, selectedRows }) => {
      if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");
      if (!selectedRows) throw new Error("selectedRows is undefined");

      const toUpdateRecordKey =
        selectedRowKeys.length === 1 ? Number(selectedRowKeys[0]) : undefined;
      const selectedPool = selectedRows.find(
        (el) => el.id === toUpdateRecordKey,
      );

      const hasArchivedCourse = selectedRows.some(
        (row) => row.course?.status === "archival",
      );

      const checkActionForArchivedCourse = (selectedPool?: Pool) => {
        if (hasArchivedCourse) {
          message.error(
            "Данное действие недоступно для потока со статусом Архивный",
          );
          return true;
        }
        return selectedPool
          ? isArchivedCourse(selectedPool!.course!.status)
          : false;
      };

      return [
        member.permissions.includes("pool:delete") && (
          <PoolRemoveButton
            key={"delete"}
            poolsIds={selectedRowKeys?.map(Number)}
            afterRemove={() => {
              actionRef.current?.clearSelected?.();
              actionRef.current?.reload();
              rest.onAfterDelete?.();
            }}
          />
        ),
        member.permissions.includes("pool:update") && (
          <PoolStartButton
            key="start"
            onBeforeUpdate={() => checkActionForArchivedCourse()}
            poolsIds={selectedRowKeys?.map(Number)}
            afterUpdate={() => actionRef.current?.reload()}
          />
        ),
        member.permissions.includes("pool:update") && (
          <PoolCompleteButton
            key="complete"
            onBeforeUpdate={() => checkActionForArchivedCourse()}
            poolsIds={selectedRowKeys?.map(Number)}
            afterUpdate={() => actionRef.current?.reload()}
          />
        ),
        member.permissions.includes("pool:update") && (
          <>
            <PoolModalForm
              key={`update-${toUpdateRecordKey}`}
              courseId={courseId}
              isArchivedCourse={() =>
                isArchivedCourse(selectedPool!.course!.status)
              }
              rest={{
                type: "update",
                recordKey: toUpdateRecordKey,
                onAfterUpdate: (pool) => {
                  actionRef.current?.reload();
                  rest.onAfterUpdate?.(pool);
                },
              }}
            />
          </>
        ),
        member.permissions.includes("pool:create") && (
          <Button
            key={"copy"}
            type={"primary"}
            disabled={selectedRows!.length !== 1}
            onClick={() => {
              if (isArchivedCourse(selectedPool!.course!.status)) return;
              setUrlParams((prev) => {
                if (selectedRows!.length !== 1)
                  throw new Error("Invalid state");

                prev.set("pool_create", "true");
                prev.set("pool_create_from", selectedRows![0].id.toString());
                return prev;
              });
            }}
          >
            Копировать
          </Button>
        ),
        member.permissions.includes("pool:create") && (
          <PoolModalForm
            key={"create"}
            courseId={courseId}
            isArchivedCourse={
              courseId ? () => isArchivedCourse(course!.status) : undefined
            }
            rest={{
              type: "create",
              onAfterCreate: (pool) => {
                actionRef.current?.reload();
                rest.onAfterCreate?.(pool);
              },
            }}
          />
        ),
      ];
    },
    pagination: { showSizeChanger: true },
    rowSelection: {},
    columns: [
      {
        title: "Курс",
        dataIndex: "course_id",
        renderText: (_, { course }) => course?.name,
        hideInTable: !!courseId,
        ...(courseId
          ? {}
          : useDynamicColumnFilters({
              request: async (params) => {
                const config: AxiosRequestConfig = {
                  method: "POST",
                  url: "/api/courses/search",
                  ...axiosConfigAdapter(),
                };

                if (params.search) {
                  config.data.filters.push({
                    field: "name",
                    operator: "ilike",
                    value: `%${params.search}%`,
                  });
                }

                return axios
                  .request<OrionRestIndexResponse<Course>>(config)
                  .then((res) => res.data)
                  .then((res) =>
                    res.data.map((course) => ({
                      label: course.name,
                      value: course.id,
                    })),
                  );
              },
              filterSearch: true,
              withNullFilter: false,
            })),
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
        renderText: (text) => (
          <span style={{ boxSizing: "border-box", wordBreak: "break-word" }}>
            {text}
          </span>
        ),
      },
      {
        title: () => "Идентификатор потока",
        formItemProps: {
          tooltip: false,
        },
        dataIndex: "key",
        hideInTable: !features.isEnabled("pools_keys"),
        hideInSearch: !features.isEnabled("pools_keys"),
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: {
          waiting_start: { text: <Tag color="orange">Ожидает запуска</Tag> },
          started: { text: <Tag color="green">Запущен</Tag> },
          completed: { text: <Tag color="red">Завершен</Tag> },
        },
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Дата и время создания",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        defaultSortOrder: "descend",
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "created_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Дата и время начала",
        tooltip: true,
        dataIndex: "starts_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "starts_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Дата и время завершения",
        tooltip: true,
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "ends_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
      {
        title: () => "Количество участников",
        tooltip: true,
        dataIndex: "participants_count",
        sorter: true,
        formItemProps: {
          name: ["range", "participants_count"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDigitRange
              {...config}
              fieldProps={{
                min: 0,
              }}
              separator="-"
              placeholder={["от", "до"]}
            />
          );
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return (
    <div ref={ref} style={{ height: "100%", width: "100%" }}>
      <ProTable<Pool> {...props} />
    </div>
  );
};

export default PoolTable;
export type { PoolTableProps };
