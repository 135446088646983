import React from "react";
import { Flex, Space, theme } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import AutomationForm from "@/entities/automation/ui/form";

const Page: React.FC = () => {
  const { token } = theme.useToken();
  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <Space
        direction={"vertical"}
        style={{
          height: "100%",
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px)",
          backgroundColor: token.colorBgContainer,
          padding: token.paddingMD,
          overflow: "auto",
        }}
      >
        <AutomationForm rest={{ type: "create" }} />
      </Space>
    </Flex>
  );
};

export default Page;
