import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Result, ResultProps, Space, Spin } from "antd";
import useSWR from "swr";
import axios from "@/axios";
import { Space as SpaceModel } from "@/entities/space/lib/model";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import useMode from "@/entities/mode/lib/use.tsx";

const Page: React.FC = () => {
  const { space_id } = useParams();
  const [title, setTitle] = React.useState<string>();
  const [subTitle, setSubTitle] = useState<string>();
  const [status, setStatus] = useState<ResultProps["status"]>();
  const mode = useMode();

  if (!space_id) throw new Error("space_id is undefined");

  const {
    data: space,
    error,
    isLoading,
  } = useSWR(
    `/api/spaces/${space_id}`,
    (url) =>
      axios
        .get<OrionRestShowResponse<SpaceModel>>(url)
        .then((res) => res.data.data),
    { refreshInterval: 250 },
  );

  if (error) throw error;

  useEffect(() => {
    if (isLoading || !space) return;

    switch (space.status) {
      case "to_create":
      case "creating":
      case "created":
        setTitle("Пространство создается...");
        break;
      case "active":
        /** On Self-Hosted mode redirect to "/" and reload the page */
        if (mode === "self-hosted") window.location.replace("/");
        setTitle("Пространство активно");
        setSubTitle(
          "Пространство создано и активно, на указанную почту отправлено письмо с данными для входа",
        );
        setStatus("success");
        break;
      case "to_deactivate":
      case "deactivating":
      case "deactivated":
        setTitle("Пространство деактивировано");
        setSubTitle(
          "Пространство деактивировано, но остается в базе данных, чтобы вы могли восстановить его в любой момент. Для восстановления пространства обратитесь в техническую поддержку",
        );
        setStatus("warning");
        break;
      case "to_delete":
      case "deleting":
      case "deleted":
        setTitle("Пространство удалено");
        setStatus("error");
        break;
    }
  }, [isLoading, space]);

  return (
    <Space direction={"vertical"} align={"center"}>
      <Result
        icon={status ? undefined : <Spin size={"large"} />}
        status={status}
        title={title}
        subTitle={subTitle}
        extra={
          space?.status === "active" && (
            <Button
              type={"primary"}
              onClick={() => {
                window.location.replace(space.url!);
              }}
            >
              Перейти в пространство
            </Button>
          )
        }
      />
    </Space>
  );
};

export default Page;
