import React, { useState } from "react";
import { PoolParticipant } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";

type Data = PoolParticipant & {
  filters?: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const OverdueEducationTable: React.FC<TableProps> = (props) => {
  const [error, setError] = useState<Error | null>(null);

  const request: ProTableProps<PoolParticipant, any>["request"] = async (
    params,
    sort,
    filter,
  ) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/pool-participants/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }

    const now = new Date().toISOString();

    config.data.filters.push(
      { field: "status", operator: "!=", value: "completed" },
      { field: "ends_at", operator: "<", value: now },
    );

    config.data.includes.push({ relation: "member" }, { relation: "pool" });

    return axios
      .request<OrionRestIndexResponse<PoolParticipant>>(config)
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    toolBarRender: false,
    request,
    pagination: {
      defaultPageSize: 20,
    },
    style: { width: "100%" },
    size: "small",
    columns: [
      {
        title: "ФИО",
        dataIndex: ["member", "full_name"],
      },
      {
        title: "Поток",
        dataIndex: ["pool", "name"],
        ellipsis: true,
      },
      {
        title: "Планируемая дата завершения",
        dataIndex: "ends_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "ascend",
      },
    ],
  };

  if (error) throw error;

  return (
    <ProTable<Data>
      {...deepmergeProTableProps(defaultProps as any, props as any)}
    />
  );
};

export default OverdueEducationTable;
