import React from "react";
import {
  ProForm,
  ProFormBaseGroupProps,
  ProFormDatePicker,
  ProFormDigit,
  ProFormList,
  ProFormText,
} from "@ant-design/pro-components";
import { date, dateNormalize } from "@/shared/dayjs/lib/formats";

type GenerateDocumentsFormGroupProps = ProFormBaseGroupProps & {
  isTemplate?: boolean;
  isRequiredLabels: boolean;
};

const GenerateDocumentsFormGroup: React.FC<GenerateDocumentsFormGroupProps> = ({
  isTemplate = false,
  isRequiredLabels = true,
  ...props
}) => {
  return (
    <ProForm.Group {...props}>
      <ProFormText
        label={"Наименование программы обучения"}
        name={"training_program_name"}
        rules={[{ required: isRequiredLabels, max: 255 }]}
      />
      <ProFormDigit
        label="Количество часов"
        name="hours_count"
        rules={[{ required: isRequiredLabels }]}
      />
      <ProFormText
        label={"Код программы в реестре"}
        name={"registry_program_code"}
        rules={[{ required: isRequiredLabels, max: 255 }]}
      />
      {!isTemplate && (
        <ProForm.Group title={"Протокол"}>
          <ProFormDatePicker
            label={"Дата в протоколе от"}
            name={"protocol_date"}
            rules={[{ required: isRequiredLabels, max: 255 }]}
            fieldProps={{ format: date }}
            normalize={dateNormalize}
          />
          <ProFormText
            label={"Номер Протокола"}
            name={"protocol_code"}
            rules={[{ required: isRequiredLabels, max: 255 }]}
          />
        </ProForm.Group>
      )}

      <ProForm.Group title={"Приказ"}>
        <ProFormDatePicker
          label={"Дата в приказе от"}
          name={"command_date"}
          rules={[{ required: isRequiredLabels, max: 255 }]}
          fieldProps={{ format: date }}
          normalize={dateNormalize}
        />
        <ProFormText
          label={"Номер Приказа"}
          name={"command_code"}
          rules={[{ required: isRequiredLabels, max: 255 }]}
        />
      </ProForm.Group>

      <ProForm.Group title={"Состав комиссии"}>
        <ProFormText
          label={"ФИО председателя"}
          name={"chairman_full_name"}
          rules={[{ required: isRequiredLabels, max: 255 }]}
        />
        <ProFormText
          label={"Должность председателя"}
          name={"chairman_position"}
          rules={[{ required: isRequiredLabels, max: 255 }]}
        />
        <ProFormList
          name={"commission_members"}
          min={2}
          max={2}
          required={isRequiredLabels}
        >
          <ProForm.Group>
            <ProFormText
              label={"ФИО участника"}
              name={"full_name"}
              rules={[{ required: isRequiredLabels, max: 255 }]}
            />
            <ProFormText
              label={"Должность участника"}
              name={"position"}
              rules={[{ required: isRequiredLabels, max: 255 }]}
            />
          </ProForm.Group>
        </ProFormList>
      </ProForm.Group>
    </ProForm.Group>
  );
};
export default GenerateDocumentsFormGroup;
export type { GenerateDocumentsFormGroupProps };
