import React from "react";
import { Button, Space, Spin, Typography } from "antd";
import { ProForm, ProFormSelect } from "@ant-design/pro-components";
import { Exam } from "@/models";
import axios from "@/axios";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { AxiosRequestConfig } from "axios";
import ExamTable from "@/entities/exam/ui/table";
import ExamReplyQuestionsTable from "@/entities/exam/ui/reply-questions-table";
import { LeftOutlined } from "@ant-design/icons";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";

interface Record {
  exam_id: Exam["id"];
}

const Page: React.FC = () => {
  const navigate = useNavigate();
  const { pool_participant_id, test_id } = useParams();

  const axiosConfig: AxiosRequestConfig = {
    method: "POST",
    url: "/api/exams/search",
    data: {
      includes: [{ relation: "config_test" }],
      filters: [
        {
          field: "status",
          operator: "in",
          value: ["completed", "failed"],
        },
        {
          field: "config_test_id",
          operator: "=",
          value: test_id,
        },
        {
          field: "pool_participant_id",
          operator: "=",
          value: pool_participant_id,
        },
      ],
    },
  };
  const {
    data: exams,
    isLoading,
    error,
  } = useSWR(axiosConfig, (config) =>
    axios<OrionRestIndexResponse<Exam>>(config).then((res) => res.data.data),
  );
  const [form] = ProForm.useForm<Record>();
  const exam_id = ProForm.useWatch("exam_id", form);

  if (!pool_participant_id || !test_id) {
    throw new Error("pool_participant_id or test_id is undefined");
  }

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!exams) throw new Error("exams is undefined");

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Button
        type={"link"}
        onClick={() => navigate(`/education/${pool_participant_id}`)}
        icon={<LeftOutlined />}
      >
        Назад к тесту
      </Button>

      <Typography.Title level={3} style={{ margin: 0 }}>
        Результат теста
      </Typography.Title>
      <ProForm<Record> form={form} submitter={false}>
        <ProFormSelect
          name={"exam_id"}
          label={"Выберите попытку"}
          initialValue={exams[0]?.id}
          options={exams.map(({ id, attempt_number }) => {
            return {
              label: `Попытка №${attempt_number}`,
              value: id,
            };
          })}
          allowClear={false}
        />
      </ProForm>
      {exam_id && (
        <>
          <ExamTable
            dataSource={exams.filter((exam) => exam.id === exam_id)}
            pagination={false}
            toolBarRender={false}
            search={false}
            columns={[
              { dataIndex: "attempt_number", hideInTable: true },
              { dataIndex: "ended_at" },
              { dataIndex: "spent_time" },
              { dataIndex: ["result", "current_score"] },
              { dataIndex: "status" },
            ]}
          />
          {exams.find((exam) => exam.id === exam_id)!.test_participant!
            .test_snapshot!.show_results_format === "indicate" && (
            <ExamReplyQuestionsTable
              dataSource={
                exams.find((exam) => exam.id === exam_id)!.result.questions
              }
              search={false}
              pagination={false}
              toolBarRender={false}
              columns={[
                { dataIndex: "text" },
                { dataIndex: "points" },
                { dataIndex: "answers.*.text.join()" },
                { dataIndex: "is_correct" },
              ]}
            />
          )}
        </>
      )}
    </Space>
  );
};

export default Page;
