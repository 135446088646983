import React from "react";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment.tsx";
import GroupTable from "@/entities/group/ui/table";
import { Link, useNavigate } from "react-router-dom";
import useMe from "@/entities/me/lib/use";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const member = useMe();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <GroupTable
        hasQueryParams
        options={{
          setting: {
            draggable: false,
            showListItemOption: false,
          },
          reload: false,
          density: false,
        }}
        columnsState={{
          persistenceKey: "groups-table",
        }}
        style={{
          width: "calc(100vw - 200px - 32px)",
          maxWidth: "calc(1920px - 200px - 32px",
        }}
        columns={[
          {
            dataIndex: "name",
            render: (_, { id: group_id, name }) => {
              if (member.permissions.includes("member:view")) {
                return <Link to={`/manage/groups/${group_id}`}>{name}</Link>;
              }
              return name;
            },
            hideInSetting: true,
            disable: true,
            ellipsis: true,
          },
          { dataIndex: "description" },
          { dataIndex: "participants_count", width: 150, align: "center" },
        ]}
        rest={{
          onAfterCreate: (group) => {
            navigate(`/manage/groups/${group.id}`);
          },
        }}
      />
    </Flex>
  );
};

export default Page;
