import React from "react";
import ServiceAccountForm, {
  ServiceAccountFormProps,
} from "@/entities/service-account/ui/form.tsx";
import Button from "@/shared/ant-design/button/ui/button.tsx";
import { Flex, Modal } from "antd";
import { deepmerge } from "deepmerge-ts";
import useSecretsModal from "@/entities/service-account/hooks/use-secrets-modal.tsx";
import { Secrets } from "@/entities/service-account/ui/secrets-descriptions.tsx";

type ServiceAccountModalFormProps = Omit<
  ServiceAccountFormProps,
  "showSecretsModal"
> & {
  //
};

const ServiceAccountModalForm: React.FC<ServiceAccountModalFormProps> = ({
  rest,
  ...formProps
}) => {
  const [open, setOpen] = React.useState(false);
  const { showSecretsModal: _showSecretsModal, modalHolder } =
    useSecretsModal();

  const showSecretsModal = async (secretsData: Secrets) => {
    await _showSecretsModal(secretsData);
  };

  const triggerText = rest.type === "create" ? "Создать" : "Редактировать";
  let disabled = rest.type === "update" && rest.recordKey === undefined;
  if (rest.type === "update" && typeof rest.recordKey === "object") {
    disabled = rest.recordKey.length !== 1;
  }

  const title =
    rest.type === "create" ? "Создание интеграции" : "Обновить интеграцию";

  if (rest.type === "update") {
    const restOnAfterUpdate = rest.onAfterUpdate;
    rest.onAfterUpdate = async (values) => {
      setOpen(false);
      await restOnAfterUpdate?.(values);
    };
  }

  if (rest.type === "create") {
    const restOnAfterCreate = rest.onAfterCreate;
    rest.onAfterCreate = async (values) => {
      setOpen(false);
      await restOnAfterCreate?.(values);
    };
  }

  const defaultFormProps: Omit<typeof formProps, "showSecretsModal"> = {
    submitter:
      rest.type === "update"
        ? {
            render: (_, dom) => {
              return (
                <Flex gap={16} justify={"end"}>
                  {dom}
                </Flex>
              );
            },
            searchConfig: {
              submitText: "Сохранить",
              resetText: "Отмена",
            },
          }
        : false,
  };

  formProps = deepmerge(defaultFormProps, formProps);

  return (
    <>
      {modalHolder}
      <Button
        type={"primary"}
        disabled={disabled}
        onClick={() => setOpen(true)}
      >
        {triggerText}
      </Button>
      <Modal
        width={800}
        open={open}
        title={title}
        destroyOnClose
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <ServiceAccountForm
          {...formProps}
          rest={rest}
          showSecretsModal={showSecretsModal}
        />
      </Modal>
    </>
  );
};

export default ServiceAccountModalForm;
export type { ServiceAccountModalFormProps };
