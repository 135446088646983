import React from "react";
import { ProTableProps } from "@ant-design/pro-table";
import { Exam } from "@/models";
import { ProTable } from "@ant-design/pro-components";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { dateSTime, toTime } from "@/shared/dayjs/lib/formats";
import examStatusValueEnum from "../lib/exam-status-value-enum";

type ExamsTableProps = ProTableProps<Exam, any>;

const ExamTable: React.FC<ExamsTableProps> = ({ ...props }) => {
  const defaultProps: Partial<typeof props> = {
    rowKey: "id",
    columns: [
      {
        title: "№ попытки",
        dataIndex: "attempt_number",
      },
      {
        title: "Время",
        dataIndex: "spent_time",
        render: (_, { spent_time }) => {
          return spent_time ? toTime(spent_time) : "-";
        },
      },
      { title: "Балл", dataIndex: ["result", "current_score"] },
      {
        title: "Дата выполнения",
        dataIndex: "ended_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
      },
      {
        title: "Результат",
        dataIndex: "status",
        valueType: "select",
        valueEnum: examStatusValueEnum,
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps, props);

  return <ProTable {...props} />;
};

export default ExamTable;
export type { ExamsTableProps };
