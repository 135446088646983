import { ReactNode } from "react";
import { Automation } from "@/models.ts";
import { useTranslation } from "react-i18next";

const useTriggerValueEnum = (): Record<Automation["trigger"], ReactNode> => {
  const { t } = useTranslation();

  return {
    on_member_saved_with_org_structure_assignments: t(
      "Сотрудник с подразделением/должностью",
    ),
    on_need_re_educate: t("Назначение повторного обучения"),
  };
};

export default useTriggerValueEnum;
