import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { deepmerge } from "deepmerge-ts";
import { Pool } from "@/models";

type SelectProps = ProFormSelectProps;

type PoolSelectProps = SelectProps;

const defaultSelectProps: SelectProps = {
  request: (params, props) => {
    const filters = [];
    if (params?.course_id) {
      filters.push({
        field: "course_id",
        operator: "=",
        value: params.course_id,
      });
    }

    filters.push({
      field: "name",
      operator: "ilike",
      value: `%${params?.keyWords ?? ""}%`,
    });

    const res = axios
      .post<{ data: Pool[] }>("/api/pools/search", { filters })
      .then(async (res) => {
        const data: any = [];
        res.data.data.forEach((pool) => {
          data.push({
            label: pool.name,
            value: pool.id,
          });
        });

        if (
          props?.fieldProps.value &&
          !data.some((obj: any) => obj.value === props.fieldProps.value)
        ) {
          const currentData = await axios
            .get(`/api/pools/${props.fieldProps.value}`)
            .then(({ data }) => ({
              label: data.data.name,
              value: data.data.id,
            }));
          data.unshift(currentData);
        }

        return data;
      });

    return res;
  },
};

const PoolSelect: React.FC<PoolSelectProps> = ({ ...selectProps }) => {
  return <ProFormSelect {...deepmerge(defaultSelectProps, selectProps)} />;
};

export default PoolSelect;
export type { PoolSelectProps };
