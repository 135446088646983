import React from "react";
import {
  ProForm,
  ProFormCheckbox,
  ProFormDigit,
  ProFormGroup,
  ProFormProps,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormTimePicker,
} from "@ant-design/pro-components";
import { TestFormProps, TestFormRecord } from "@/entities/test/ui/form";
import { Button, Col, Flex, message, Modal, Row, Space, theme } from "antd";
import dayjs from "dayjs";
import { testTypeValueEnum } from "@/entities/test/lib/type-value-enum";
import axios from "@/axios";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import ImportButton from "@/entities/import/ui/button";
import useMe from "@/entities/me/lib/use";

type TestFormFieldsProps = {
  form: Required<ProFormProps<TestFormRecord>>["form"];
  type: TestFormProps["type"];
  onDelete?: () => void;
  onAfterImportQuestions?: () => Promise<void>;
};

const TestFormFields: React.FC<TestFormFieldsProps> = ({
  form: _form,
  type,
  onDelete: _onDelete,
  onAfterImportQuestions,
}) => {
  const [form] = ProForm.useForm<TestFormRecord>(_form);
  const [modal, modalHolder] = Modal.useModal();
  const {
    token: { marginLG },
  } = theme.useToken();
  const member = useMe();

  const is_questions_database = ProForm.useWatch("is_questions_database", form);
  const is_limits_testing_time = ProForm.useWatch(
    "is_limits_testing_time",
    form,
  );
  const is_limits_testing_attempts = ProForm.useWatch(
    "is_limits_testing_attempts",
    form,
  );

  const onDelete = async () => {
    modal.warning({
      title: "Удалить текущий тест?",
      icon: null,
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      closable: true,
      onOk: () => _onDelete?.(),
    });
  };

  const onAfterImportQuestionsFinish = async () => {
    const questions = await axios
      .get<OrionRestShowResponse<TestFormRecord>>(
        `/api/tests/${form.getFieldValue("id")}`,
      )
      .then((res) => res.data.data.questions);

    form.setFieldsValue({ questions });
    await onAfterImportQuestions?.();
  };

  const onBeforeImport = async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch {
      message.error("Пожалуйста, заполните все поля перед импортом.");
      return true;
    }
    return false;
  };

  return (
    <>
      {modalHolder}
      <Flex justify={"end"} gap={16} style={{ width: "100%" }}>
        {member.permissions.includes("course:update") && (
          <>
            <Button type={"primary"} danger onClick={onDelete}>
              Удалить тест
            </Button>
            {member.permissions.includes("import:questions") && (
              <ImportButton
                type="questions"
                testId={form.getFieldValue("id")}
                onBeforeImport={onBeforeImport}
                onAfterFinish={() => onAfterImportQuestionsFinish()}
                trigger={<Button type="primary">Импорт вопросов</Button>}
              />
            )}
            <Button type="primary" onClick={() => form.submit()}>
              Сохранить
            </Button>
          </>
        )}
      </Flex>
      <ProFormDigit name={"course_id"} rules={[{ required: true }]} hidden />
      <Row gutter={24} style={{ marginTop: marginLG }}>
        <Col span={12}>
          <ProFormSelect
            name={"type"}
            label={"Тип теста"}
            valueEnum={testTypeValueEnum}
            rules={[{ required: true }]}
          />

          <ProFormText
            name={"name"}
            label={"Название"}
            rules={[{ required: true, max: 255 }]}
          />
          <ProFormTextArea name={"description"} label={"Описание"} />

          <ProFormTextArea name={"note"} label={"Внутренняя заметка"} />

          <ProFormCheckbox name={"is_shuffles_questions"}>
            Перемешать порядок вопросов
          </ProFormCheckbox>

          <ProFormGroup>
            <ProFormCheckbox
              name={"is_questions_database"}
              rules={[{ required: true }]}
              fieldProps={{
                onChange: (event) => {
                  if (!event.target.checked) {
                    form.setFieldsValue({ questions_database_limit: null });
                  }
                },
              }}
            >
              Ограничить количество вопросов
            </ProFormCheckbox>
            <ProFormDigit
              hidden={!is_questions_database}
              name={"questions_database_limit"}
              rules={[{ required: is_questions_database }]}
            />
          </ProFormGroup>
        </Col>
        <Col span={12}>
          <ProFormRadio.Group
            name={"is_limits_testing_time"}
            label={"Время прохождения"}
            layout={"vertical"}
            options={[
              { label: "Без ограничений", value: false },
              {
                label: (
                  <Space direction={"horizontal"} align={"center"} wrap={false}>
                    Ограничено
                    <ProFormTimePicker
                      hidden={!is_limits_testing_time}
                      name={"testing_time_limit"}
                      fieldProps={{ showNow: false }}
                      convertValue={(value: number) => {
                        if (!value) return dayjs().startOf("day");

                        return dayjs()
                          .startOf("day")
                          .add(value, "second")
                          .format("HH:mm:ss");
                      }}
                      normalize={(value) => {
                        if (value)
                          return value.$H * 3600 + value.$m * 60 + value.$s;
                      }}
                      rules={[
                        { required: is_limits_testing_time },
                        {
                          validator: async (_, value) => {
                            if (value && value < 60) {
                              throw new Error("Минимальное время – 1 минута");
                            }
                          },
                        },
                      ]}
                      formItemProps={{
                        style: { margin: 0 },
                      }}
                      allowClear={false}
                    />
                  </Space>
                ),
                value: true,
              },
            ]}
            fieldProps={{
              onChange: (event) => {
                if (event.target.value === false) {
                  form.setFieldsValue({ testing_time_limit: null });
                }
              },
            }}
          />
          <ProFormRadio.Group
            name="is_limits_testing_attempts"
            label="Количество попыток"
            layout="vertical"
            options={[
              { label: "Без ограничений", value: false },
              {
                label: (
                  <Space direction={"horizontal"} align={"center"} wrap={false}>
                    Ограничено
                    <ProFormDigit
                      hidden={!is_limits_testing_attempts}
                      name={"testing_attempts_limit"}
                      rules={[{ required: is_limits_testing_attempts }]}
                      min={1}
                      max={100}
                      formItemProps={{ style: { margin: 0 } }}
                    />
                  </Space>
                ),
                value: true,
              },
            ]}
            fieldProps={{
              onChange: (event) => {
                if (event.target.value === false) {
                  form.setFieldsValue({ testing_attempts_limit: null });
                }
              },
            }}
          />
          <ProFormRadio.Group
            name="show_results_format"
            label="Отображение результатов теста"
            layout="vertical"
            options={[
              { value: "hidden", label: "Не показывать" },
              {
                value: "indicate",
                label:
                  "Отображение выбранных и указание правильный/неправильный",
              },
            ]}
          />
          <ProFormDigit
            label={"Критерий успешного завершения теста"}
            rules={[{ required: true }]}
          >
            <Space direction={"horizontal"} align={"center"} wrap={false}>
              Успешное выполнение на
              <ProFormDigit
                name={"success_criteria"}
                rules={[{ required: true }]}
                formItemProps={{ style: { margin: 0 } }}
                min={0}
                max={100}
              />
              %
            </Space>
          </ProFormDigit>
        </Col>
      </Row>
    </>
  );
};

export default TestFormFields;
export type { TestFormFieldsProps };
