import { ProColumns } from "@ant-design/pro-table/es/typing";
import { useEffect, useState } from "react";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { debounce } from "lodash";

function useDynamicColumnFilters<DataSource, ValueType>({
  request,
  filterSearch,
  withNullFilter,
}: Pick<ProColumns<DataSource, ValueType>, "request" | "filterSearch"> & {
  withNullFilter?: boolean;
}): Pick<
  ProColumns<DataSource, ValueType>,
  "filters" | "request" | "onFilterDropdownOpenChange" | "filterSearch"
> {
  if (withNullFilter === undefined) {
    withNullFilter = true;
  }

  if (request === undefined) {
    throw new Error(
      "Param 'request' must be defined when using useDynamicFilters",
    );
  }

  const [filters, setFilters] = useState<ColumnFilterItem[]>([]);

  const updateFilters = (params: any = {}) => {
    request(params, {}).then((data) => {
      let newFilters = data.map((value) => ({
        text: value.label,
        value: value.value as string,
      }));

      if (withNullFilter) {
        newFilters = [
          {
            text: "Без значения",
            value: null as any,
          },
          ...newFilters,
        ];
      }

      setFilters(newFilters);
    });
  };

  const debouncedUpdateFilters = debounce(updateFilters, 1000);

  if (typeof filterSearch === "boolean" && filterSearch) {
    filterSearch = (value, record) => {
      debouncedUpdateFilters({ search: value });
      return (
        record.text !== null &&
        record.text !== undefined &&
        record.text.toString().toLowerCase().includes(value.toLowerCase())
      );
    };
  }

  useEffect(() => {
    updateFilters();
  }, []);

  return {
    request,
    filterSearch,
    filters,
    onFilterDropdownOpenChange: (open) => {
      if (open) updateFilters();
    },
  };
}

export default useDynamicColumnFilters;
