import React, { useEffect } from "react";
import { Scorm } from "@/models";
import { Button, Flex, message, Result, theme } from "antd";
import Spin from "@/shared/components/spin";
import useSWR from "swr";
import axios from "@/axios";
import "scorm-again";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useHeader from "@/entities/layout/lib/use-header";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";

type BaseScormDescriptionsProps = {
  dataSource: Scorm;
};
type ScormDescriptionsProps = BaseScormDescriptionsProps & {
  onAfterSuccessFinish?: () => void;
  poolParticipantId: string;
};

declare global {
  interface Window {
    API?: any;
    API_1484_11?: any;
  }
}

const ScormDescriptions: React.FC<ScormDescriptionsProps> = ({}) => {
  const { pool_participant_id, scorm_id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { token } = theme.useToken();
  const contextValue = useHeader();

  useEffect(() => {
    contextValue!.setHidden(true);
    return () => {
      contextValue!.setHidden(false);
    };
  }, []);

  const navigateToCourse = () => {
    navigate(
      searchParams.get("pool_participant_url") ??
        `/education/${pool_participant_id}`,
    );
  };

  const {
    data: scorm,
    error: scormError,
    isLoading: scormIsLoading,
  } = useSWR(`/api/scorms/${scorm_id}`, (url) =>
    axios
      .get<OrionRestShowResponse<Scorm>>(url)
      .then(async (res) => res.data.data),
  );

  const {
    data: manifest,
    error,
    isLoading,
  } = useSWR(scorm ? `${scorm.url}/imsmanifest.xml` : null, async (url) => {
    return axios
      .get(url)
      .then(({ data }) => new DOMParser().parseFromString(data, "text/xml"));
  });

  const [scormStatus, setScormStatus] = React.useState<
    "failed" | "completed" | undefined
  >(undefined);

  const onAfterSuccessFinish = () => {
    const educationMarkId = searchParams.get("education_mark_id");
    if (educationMarkId) {
      axios
        .put(`/api/education-marks/${educationMarkId}`, {
          value: "completed",
        })
        .then(() => navigateToCourse());
    }
  };

  useEffect(() => {
    if (scormStatus === "completed") {
      message.success("Вы успешно завершили SCORM");
      onAfterSuccessFinish();
    }
  }, [scormStatus]);

  useEffect(() => {
    const settings = {};

    if (manifest) {
      const scormsKey = `scorms:${scorm!
        .url!}/pool-participant:${pool_participant_id}`;
      const scormVersion =
        manifest.getElementsByTagName("schemaversion")[0]?.textContent;

      const suspendData = localStorage.getItem(scormsKey);

      if (scormVersion === "1.2") {
        //@ts-ignore
        window.API = new Scorm12API(settings);
        window.API.on("LMSSetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API.cmi.suspend_data);
        });

        if (suspendData) {
          window.API.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API.on("LMSSetValue.cmi.lesson_status", () => {
          if (
            window.API.cmi.lesson_status === "completed" &&
            scormStatus !== "completed"
          ) {
            setScormStatus("completed");
          } else if (window.API.cmi.lesson_status === "failed") {
            setScormStatus("failed");
          }
        });
      } else {
        //@ts-ignore
        window.API_1484_11 = new Scorm2004API(settings);

        window.API_1484_11.on("SetValue.cmi.suspend_data", () => {
          localStorage.setItem(scormsKey, window.API_1484_11.cmi.suspend_data);
        });

        if (suspendData) {
          window.API_1484_11.loadFromJSON(
            {
              cmi: {
                suspend_data: suspendData,
              },
            },
            "",
          );
        }

        window.API_1484_11.on("SetValue.cmi.completion_status", () => {
          if (
            window.API_1484_11.cmi.completion_status === "completed" &&
            window.API_1484_11.cmi.success_status !== "failed" &&
            scormStatus !== "completed"
          ) {
            setScormStatus("completed");
          } else if (window.API_1484_11.cmi.success_status === "failed") {
            setScormStatus("failed");
          }
        });
      }
    }

    return () => {
      if (window.API) {
        window.API = null;
      }
      if (window.API_1484_11) {
        window.API_1484_11 = null;
      }
    };
  }, [manifest]);

  if (scormIsLoading || isLoading) return <Spin />;
  if (scormError || error) throw error;
  if (!scorm) throw new Error("Не удалось загрузить SCORM");
  if (!manifest) throw new Error("Не удалось загрузить содержимое пакета");

  switch (scorm.status) {
    case "pending":
      return (
        <Result
          status={"warning"}
          title={"Пакет еще не загружен"}
          subTitle={"Пожалуйста, обратитесь к администратору"}
        />
      );
    case "active":
      break;
    default:
      return (
        <Result
          status={"error"}
          title={"Пакет не активен"}
          subTitle={"Пожалуйста, дождитесь активации пакета"}
        />
      );
  }

  const href = manifest
    .getElementsByTagName("resource")[0]
    .getAttribute("href");

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        zIndex: 99999,
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Flex
        justify="start"
        style={{ width: "100%", backgroundColor: token.colorBgContainer }}
      >
        <Button type="link" onClick={() => navigateToCourse()}>
          Вернуться к курсу
        </Button>
      </Flex>
      <iframe
        loading="eager"
        src={`${scorm.url}/${href}`}
        style={{
          width: "100%",
          height: "calc(100% - 32px)",
          border: "none",
        }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        sandbox={
          "allow-scripts allow-same-origin allow-popups allow-forms allow-downloads"
        }
      />
    </div>
  );
};

export default ScormDescriptions;
export type { ScormDescriptionsProps };
