import React from "react";
import { ProFormDigitRange } from "@ant-design/pro-components";
import MemberSelect from "@/entities/member/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import MemberDepartmentInput from "@/entities/member/ui/department-input";
import MemberPositionInput from "@/entities/member/ui/position-input";
import { useTranslation } from "react-i18next";

const EducationStatisticsByDepartmentFormFields: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <MemberDepartmentInput
        label={t("Подразделение")}
        colProps={{ span: 8 }}
        name={["member", "org_structure_assignments", "department_id"]}
        mode="multiple"
        hideAddInput
      />
      <MemberPositionInput
        label={t("Должность")}
        name={["member", "org_structure_assignments", "position_id"]}
        colProps={{ span: 8 }}
        mode="multiple"
        hideAddInput
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <ProFormDigitRange
        label="Прогресс прохождения"
        colProps={{ span: 8 }}
        name={["range", "progress_percent"]}
        fieldProps={{
          max: 100,
          min: 0,
        }}
        separator="-"
        placeholder={["от", "до"]}
      />
    </>
  );
};

export default EducationStatisticsByDepartmentFormFields;
