import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EducationMark,
  Exam,
  Lesson,
  PoolParticipant,
  Test,
  TreeNode,
} from "@/models";
import useSWR from "swr";
import axios from "@/axios";
import {
  Button,
  Col,
  Flex,
  Grid,
  Modal,
  Row,
  Space,
  Spin,
  theme,
  TreeDataNode as BaseTreeDataNode,
  TreeProps,
  Typography,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ExamTable from "@/entities/exam/ui/table";
import dayjs from "dayjs";
import TestResult from "./tests/[test_id]/results/result";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import "./page.css";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import useQueryState from "@/shared/hooks/use-query-state";
import NotFoundPage from "@/shared/pages/not-found";
import CourseTree, {
  dataMapper,
  flattenTree,
  SWRHook,
} from "@/pages/space/education/[pool_participant_id]/embeds-tree";

const { useBreakpoint } = Grid;

type TreeDataNode = BaseTreeDataNode & {
  meta: {
    treeNode: TreeNode;
    educationMark?: EducationMark;
  };
};

const descriptionsGlob = import.meta.glob<React.FC<any>>(
  "@/entities/*/ui/descriptions.tsx",
  {
    eager: true,
    import: "default",
  },
);

const ColContentWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        backgroundColor: token.colorBgContainer,
        padding: token.padding,
        borderRadius: token.borderRadius,
      }}
    >
      {children}
    </div>
  );
};

const Page: React.FC = () => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { pool_participant_id } = useParams();
  const screens = useBreakpoint();
  const swrRef = React.useRef<SWRHook>(null);
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(true);

  const { data, isLoading, error, mutate } = useSWR(
    [
      `/api/pool-participants/${pool_participant_id}`,
      {
        include: [
          "pool",
          "pool.course",
          "pool.course.tree_node",
          "pool.course.tree_node.tree",
          "education_marks",
          "exams",
          "exams.test_participant",
        ].join(","),
      },
    ],
    ([url, params]) =>
      axios
        .get<OrionRestShowResponse<PoolParticipant>>(url, { params })
        .then((res) => res.data.data),
  );

  const [selectedTreeNode, setSelectedTreeNode] = useState<TreeNode>();

  type Selected = React.Key | undefined;

  const [selectedNodeKey, setSelectedNodeKey] = useQueryState<Selected>(
    "selected_tree_node",
    {
      valueType: "number",
    },
  );

  const [indexCurrentTree, setIndexCurrentTree] = useState<number>(0);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [showEducationTreeModal, setShowEducationTreeModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (isModalVisible && !screens.sm) {
      setIsModalVisible(false);
    }
  }, [screens.sm]);

  useEffect(() => {
    if (selectedTreeNode?.is_an_type === "test") {
      const exams =
        data
          ?.exams!.filter(
            (exam) =>
              exam.test_participant!.test_id === selectedTreeNode.is_an_id,
          )
          .sort((first, second) => {
            return (
              dayjs(first.created_at).unix() - dayjs(second.created_at).unix()
            );
          }) ?? [];

      const lastExam = exams[exams.length - 1];

      let interval: undefined | NodeJS.Timeout;
      if (
        lastExam &&
        lastExam.status !== "completed" &&
        lastExam.status !== "failed" &&
        lastExam.status !== "not_started"
      ) {
        interval = setInterval(() => {
          mutate();
        }, 1000);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [selectedTreeNode]);

  const onNextEducationIsViewAvailable = (isViewAvialable: boolean) => {
    setDisabledNextButton(isViewAvialable);
  };

  const onSelectKey = (key: React.Key) => {
    if (key) {
      setSelectedNodeKey(key);
    }
  };

  const treeData: TreeProps<TreeDataNode>["treeData"] =
    // @ts-ignore
    data?.pool!.course!.tree_node?.tree.map((node) =>
      dataMapper(node, data.education_marks!),
    ) ?? [];

  const flatTreeData = flattenTree(treeData || []);

  const educationMarkCompleted = (markId: EducationMark["id"]) => {
    axios
      .put(`/api/education-marks/${markId}`, { value: "completed" })
      .then(() => swrRef.current?.mutate());
  };

  const TreeNodeDescriptions: React.FC = () => {
    if (!selectedTreeNode) {
      return (
        <Space direction="vertical">
          <Typography.Text>
            Выберите содержание из структуры курса
          </Typography.Text>
        </Space>
      );
    }

    const TreeNodeDescriptions =
      descriptionsGlob[
        `/src/entities/${selectedTreeNode.is_an_type}/ui/descriptions.tsx`
      ];

    let props: {
      dataSource: any;
      onScormStart?: () => void;
    } = {
      dataSource: selectedTreeNode.is_an,
    };

    if (selectedTreeNode.is_an_type === "scorm") {
      const currentTreeNode = flatTreeData?.find(
        (treeNode) =>
          treeNode.meta?.treeNode?.is_an_type === "scorm" &&
          treeNode.meta?.treeNode?.is_an?.id === selectedTreeNode.is_an_id,
      );
      const educationMark = currentTreeNode?.meta?.educationMark;
      props = {
        ...props,
        onScormStart: () =>
          navigate(
            `/education/${pool_participant_id}/scorms/${props.dataSource.id}
              ?pool_participant_url=${window.location.pathname}${
              window.location.search
            }${
              educationMark && educationMark.value !== "completed"
                ? `&education_mark_id=${educationMark.id}`
                : ""
            }`,
          ),
      };
    }

    let result = <TreeNodeDescriptions {...props} />;

    if (selectedTreeNode.is_an_type === "test") {
      const test: Test = selectedTreeNode.is_an;

      const exams =
        data?.exams!.filter(
          (exam) =>
            exam.test_participant!.test_id === selectedTreeNode.is_an_id,
        ) ?? [];

      const currentExam = exams.find(
        (exam) =>
          exam.status === "launched" ||
          exam.status === "not_started" ||
          exam.status === "completed",
      );

      const lastExam = exams[exams.length - 1];

      const onExamStart = async () => {
        if (currentExam!.status === "not_started") {
          await axios.put(`/api/exams/${currentExam!.id}`, {
            status: "launched",
          });
        }

        navigate(
          `/education/${pool_participant_id}/exams/${
            currentExam!.id
          }?pool_participant_url=${window.location.pathname}${
            window.location.search
          }`,
        );
      };

      const examStatusButtonTextEnum: { [key in Exam["status"]]?: string } = {
        not_started: "Начать",
        launched: "Продолжить",
        completed: "Сдан",
      };

      const totalExamsCount = exams.filter(
        (exam) => exam.status !== "not_started" && exam.status !== "launched",
      ).length;

      let attemptsText = `${totalExamsCount}`;
      if (
        lastExam &&
        lastExam.test_participant!.test_snapshot.is_limits_testing_attempts
      ) {
        attemptsText += `/${
          lastExam.test_participant!.test_snapshot.testing_attempts_limit ?? ""
        }`;
      }

      const examsTableDataSource = exams
        // сначала новые
        .sort((first, second) => {
          return (
            dayjs(second.created_at).unix() - dayjs(first.created_at).unix()
          );
        })
        // последние 3
        .slice(0, 3);
      const showResultsButton = examsTableDataSource.some(
        (exam) => exam.status === "failed" || exam.status === "completed",
      );

      result = (
        <Space direction={"vertical"}>
          <Flex wrap={screens.sm ? "nowrap" : "wrap"} justify="end">
            {result}
            {currentExam && (
              <Button
                onClick={onExamStart}
                type={"primary"}
                block
                disabled={currentExam.status === "completed"}
                style={{
                  background:
                    currentExam.status === "completed"
                      ? token.blue1
                      : token.colorPrimary,
                }}
              >
                {examStatusButtonTextEnum[currentExam!.status]}
              </Button>
            )}
          </Flex>
          <Flex justify={"space-between"}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Попытки: {attemptsText}
            </Typography.Title>
          </Flex>
          {exams.length > 0 &&
            (screens.sm ? (
              <ExamTable
                className={"my-education-exams-table"}
                pagination={false}
                search={false}
                toolbar={{
                  title: (
                    <Typography.Title level={5}>
                      Результаты теста
                    </Typography.Title>
                  ),
                  actions: [
                    test.show_results_format === "indicate" &&
                      showResultsButton && (
                        <Button
                          key={"more"}
                          type={"link"}
                          onClick={() => setIsModalVisible(true)}
                        >
                          Подробнее
                        </Button>
                      ),
                  ],
                  settings: [],
                }}
                dataSource={examsTableDataSource}
                columns={[
                  { dataIndex: "attempt_number" },
                  { dataIndex: "spent_time" },
                  { dataIndex: ["result", "current_score"] },
                  { dataIndex: "ended_at" },
                  { dataIndex: "status" },
                ]}
              />
            ) : (
              <TestResult test_id={selectedTreeNode!.is_an.id} />
            ))}
        </Space>
      );
    }

    return result;
  };

  const onNodeSelect: TreeProps<TreeDataNode>["onSelect"] = async (
    selectedKey,
    {
      node: {
        meta: { treeNode: node, educationMark: mark },
      },
    },
  ) => {
    if (node.is_an_type === "lesson") {
      node.is_an = await axios
        .get(`/api/lessons/${node.is_an.id}?include=content_file`)
        .then((res) => res.data.data);
    }
    setSelectedTreeNode(node);
    setShowEducationTreeModal(false);
    onSelectKey(selectedKey[0]);

    const indexCurrentTree = flatTreeData.findIndex(
      (course) => course.meta.treeNode.id === node.id,
    );

    setIndexCurrentTree(indexCurrentTree);
    if (
      node.is_an_type !== "test" &&
      node.is_an_type !== "scorm" &&
      mark &&
      mark.value === "not_completed"
    ) {
      educationMarkCompleted(mark.id);
    }
  };

  const moveNode = (direction: "next" | "prev") => {
    if (!selectedNodeKey || !treeData) {
      return;
    }

    const currentIndex = flatTreeData.findIndex(
      (node) => node.key === selectedNodeKey,
    );
    let newIndex = currentIndex;
    if (direction === "next") {
      newIndex =
        currentIndex !== -1 && currentIndex < flatTreeData.length - 1
          ? currentIndex + 1
          : currentIndex;
    } else if (direction === "prev") {
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (!flatTreeData[i].disabled) {
          newIndex = i;
          break;
        }
      }
    } else {
      return;
    }
    if (
      newIndex !== currentIndex &&
      newIndex >= 0 &&
      newIndex < flatTreeData.length
    ) {
      const newNodeKey = flatTreeData[newIndex].key;

      //@ts-ignore
      onNodeSelect([newNodeKey], { node: flatTreeData[newIndex] });
    }
  };

  useEffect(() => {
    if (data) {
      if (flatTreeData.length === 1) {
        //@ts-ignore
        onNodeSelect([flatTreeData[0].key], { node: flatTreeData[0] });
        return;
      }
      if (!selectedNodeKey) {
        setSelectedTreeNode(undefined);
        return;
      }

      let currentIndex = flatTreeData.findIndex(
        (node) => node.key === selectedNodeKey,
      );
      let currentKey = selectedNodeKey;

      if (
        !(
          flatTreeData[currentIndex]?.meta.educationMark === undefined ||
          flatTreeData[currentIndex]?.meta.educationMark!.is_view_available
        ) ||
        currentIndex === -1
      ) {
        currentKey = flatTreeData[0].key;
        currentIndex = 0;
      }

      //@ts-ignore
      onNodeSelect([currentKey], { node: flatTreeData[currentIndex] });
    }
  }, [data]);

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("Data is undefined");
  if (data.pool?.status !== "started") return <NotFoundPage />;

  const TreeContentEducation: React.FC = () => (
    <CourseTree
      swrRef={swrRef}
      onNextEducationIsViewAvailable={onNextEducationIsViewAvailable}
      indexCurrentTree={indexCurrentTree}
      poolParticipantId={pool_participant_id}
      className={"my-education-tree"}
      blockNode
      selectedKeys={[selectedNodeKey]}
      onSelect={onNodeSelect}
    />
  );

  return (
    <>
      <Modal
        onCancel={() => setShowEducationTreeModal(false)}
        footer={<></>}
        closeIcon={<></>}
        open={showEducationTreeModal}
      >
        <div style={{ maxHeight: "70vh", overflow: "auto" }}>
          <TreeContentEducation />
        </div>
      </Modal>
      <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
        <AutoBreadcrumb />
        {isModalVisible && (
          <TestResult
            modal
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            test_id={selectedTreeNode!.is_an.id}
          />
        )}
        <Row
          gutter={[16, 16]}
          align={"stretch"}
          style={{ height: "calc(100% - 30px)" }}
        >
          {screens.lg && flatTreeData.length > 1 && (
            <Col md={8} lg={8} xl={6} xxl={4} style={{ height: "100%" }}>
              <ColContentWrapper>
                <TreeContentEducation />
              </ColContentWrapper>
            </Col>
          )}
          <Col
            style={{ height: "100%" }}
            {...(flatTreeData.length <= 1
              ? { span: 24 }
              : { xxl: 20, xl: 18, lg: 16 })}
            md={24}
            sm={24}
            xs={24}
          >
            <ColContentWrapper>
              <Row style={{ height: "100%" }} gutter={[0, 12]}>
                <Flex
                  vertical
                  gap={8}
                  style={{
                    justifyContent: "space-between",
                    height: "100%",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  <Flex
                    vertical
                    gap={8}
                    style={{ width: "100%", height: "calc(100% - 40px)" }}
                  >
                    {!screens.lg && flatTreeData.length > 1 && (
                      <Button onClick={() => setShowEducationTreeModal(true)}>
                        <Typography.Text strong>Содержание</Typography.Text>
                      </Button>
                    )}

                    <TreeNodeDescriptions />
                  </Flex>
                  {selectedTreeNode && (
                    <Flex
                      justify={indexCurrentTree === 0 ? "end" : "space-between"}
                      style={{ width: "100%" }}
                    >
                      {indexCurrentTree !== 0 && (
                        <Button
                          icon={<LeftOutlined />}
                          onClick={() => moveNode("prev")}
                        >
                          {screens.sm ? "Предыдущий" : null}
                        </Button>
                      )}
                      {indexCurrentTree !== flatTreeData.length - 1 && (
                        <Button
                          disabled={disabledNextButton}
                          type="primary"
                          onClick={() => moveNode("next")}
                        >
                          {screens.sm ? (
                            <>
                              Следующий <RightOutlined />
                            </>
                          ) : (
                            <RightOutlined />
                          )}
                        </Button>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Row>
            </ColContentWrapper>
          </Col>
        </Row>
      </Flex>
    </>
  );
};

export default Page;
