import React from "react";
import { DeleteRestProps } from "@/shared/rest/lib/types";
import Button from "@/shared/ant-design/button/ui/button";
import axios from "@/axios";
import { message, Modal } from "antd";
import { ServiceAccount } from "@/models";

type ServiceAccountDeleteButtonProps = {
  rest: Required<Omit<DeleteRestProps<ServiceAccount["id"]>, "type">>;
};

const ServiceAccountDeleteButton: React.FC<ServiceAccountDeleteButtonProps> = ({
  rest,
}) => {
  const [modal, modalHolder] = Modal.useModal();

  if (typeof rest.recordKey !== "object") {
    rest.recordKey = [rest.recordKey];
  }

  const onDelete = async () => {
    modal.confirm({
      title: "Удалить сервисный аккаунт",
      content:
        "Выбранный аккаунт будет удален, интеграция с подключенным сервисом перестанет производиться",
      okText: "Удалить",
      okButtonProps: { danger: true, type: "primary" },
      cancelText: "Отмена",
      onOk: () =>
        axios
          .request({
            method: "DELETE",
            url: `/api/service-accounts/batch`,
            data: { resources: rest.recordKey },
          })
          .then(() => {
            rest.onAfterDelete?.();
            message.success("Сервисный аккаунт удален");
          })
          .catch(() => {
            message.error("Не удалось удалить сервисный аккаунт");
          }),
    });
  };

  const disabled = rest.recordKey.length === 0;

  return (
    <>
      {modalHolder}
      <Button type={"primary"} danger onClick={onDelete} disabled={disabled}>
        Удалить интеграцию
      </Button>
    </>
  );
};

export default ServiceAccountDeleteButton;
export type { ServiceAccountDeleteButtonProps };
