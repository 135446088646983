import React from "react";
import { ProFormDateTimeRangePicker } from "@ant-design/pro-components";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import MemberSelect from "@/entities/member/ui/select";
import PoolSelect from "@/entities/pool/ui/select";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

const CompletedEducationMembersFormFields: React.FC = ({}) => {
  return (
    <>
      <MemberSelect
        mode="multiple"
        colProps={{ span: 8 }}
        label="ФИО"
        name={"member_id"}
      />
      <PoolSelect
        mode="multiple"
        label="Поток"
        name={"pool_id"}
        colProps={{ span: 8 }}
      />
      <ProFormDateTimeRangePicker
        label="Фактическая дата завершения"
        name={["range", "ended_at"]}
        fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
        colProps={{ span: 8 }}
        normalize={normalizeDateRange}
      />
    </>
  );
};

export default CompletedEducationMembersFormFields;
