import React, { useCallback, useEffect } from "react";
import axios from "@/axios";
import {
  Button,
  Col,
  ColProps,
  Dropdown,
  Flex as AntdFlex,
  FlexProps,
  message,
  Row,
  theme,
  TreeProps,
  Typography,
} from "antd";
import {
  Course,
  Lesson,
  Longread,
  Scorm,
  Section,
  Test,
  TreeNode,
} from "@/models";
import CourseEmbedsTree, {
  CourseEmbedsTreeProps,
  DataNode,
  SWRHook,
} from "@/pages/space/manage/courses/[course_id]/embeds-tree";
import TestForm from "@/entities/test/ui/form";
import { deepmerge } from "deepmerge-ts";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
} from "@/shared/types/orion-rest";
import useQueryState from "@/shared/hooks/use-query-state";
import { RestProps } from "@/shared/rest/lib/types";
import useMe from "@/entities/me/lib/use";

type CourseEmbedsTreeEditorProps = TreeProps<DataNode> & {
  courseId: Course["id"];
};

const formsGlob = import.meta.glob<React.FC<any>>("@/entities/*/ui/form.tsx", {
  eager: true,
  import: "default",
});

const Flex: React.FC<FlexProps> = ({ children, ...props }) => {
  const { token } = theme.useToken();
  const defaultProps: ColProps = {
    style: {
      width: "100%",
      height: "100%",
      backgroundColor: token.colorBgContainer,
      padding: token.paddingMD,
    },
  };

  props = deepmerge(defaultProps, props);

  return <AntdFlex {...props}>{children}</AntdFlex>;
};

const CourseEmbedsTreeEditor: React.FC<CourseEmbedsTreeEditorProps> = ({
  courseId,
  ...props
}) => {
  const { token } = theme.useToken();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const swrRef = React.useRef<SWRHook>(null);
  const member = useMe();

  const [expandendKeys, setExpandendKeys] = React.useState<React.Key[]>([]);
  const onExpand: CourseEmbedsTreeProps["onExpand"] = (expandedKeys, info) => {
    const treeChild = info.node.meta.treeNode.tree;
    if (info.expanded && treeChild && treeChild.length > 0) {
      swrRef.current?.scrollTo(treeChild[treeChild.length - 1].id);
    }
    setExpandendKeys(expandedKeys);
  };

  useEffect(() => {
    axios
      .get<OrionRestShowResponse<Course>>(
        `/api/courses/${String(courseId)}?include=tree_node,tree_node.tree`,
      )
      .then((res) => res.data.data)
      .then((course) => {
        // @ts-ignore
        const defaultExpanendedKeys = course.tree_node.tree.map(
          (node: TreeNode) => node.id,
        );
        setExpandendKeys(defaultExpanendedKeys);
      });
  }, []);

  type Selected = React.Key | undefined;
  const [selected, setSelected] = useQueryState<Selected>(
    "selected_tree_node",
    {
      defaultValue: undefined,
      valueType: "array",
    },
  );
  const [selectedTreeNode, setSelectedTreeNode] = React.useState<TreeNode>();

  useEffect(() => {
    if (!selected) {
      setSelectedTreeNode(undefined);
      return;
    }

    axios
      .request<OrionRestShowResponse<TreeNode>>({
        method: "GET",
        url: `/api/tree-nodes/${selected}`,
      })
      .then((res) => res.data.data)
      .then((treeNode) => {
        setSelectedTreeNode(treeNode);
      });
  }, [selected]);

  const reBindContentToTree = async (
    type: string,
    embed: Section | Lesson | Test | Scorm | Longread,
  ) => {
    const embedTreeNode: TreeNode = await axios
      .get<OrionRestShowResponse<typeof embed>>(`/api/${type}s/${embed.id}`, {
        params: {
          include: "tree_node",
        },
      })
      // @ts-ignore
      .then((res) => res.data.data.tree_node);

    if (selectedTreeNode?.is_an_type === "section" && type !== "section") {
      await axios.put(`/api/tree-nodes/${embedTreeNode.id}`, {
        parent_tree_node_id: selectedTreeNode.id,
        order_index: -1,
      });
    }

    setSelected(embedTreeNode.id);
  };

  const onCreateSection = async () => {
    return axios
      .post<OrionRestCreateResponse<Section>>("/api/sections", {
        course_id: courseId,
        name: "Раздел",
      })
      .then(async ({ data: { data: section } }) => {
        await reBindContentToTree("section", section);
      })
      .catch((err) => {
        message.error(err.response.data.message ?? "Не удалось создать раздел");
      })
      .finally(async () => {
        await swrRef.current?.mutate();
      });
  };

  const onCreateLesson = async () => {
    return axios
      .post<OrionRestCreateResponse<Lesson>>("/api/lessons", {
        course_id: courseId,
        name: "Урок",
      })
      .then(async ({ data: { data: lesson } }) => {
        await reBindContentToTree("lesson", lesson);
      })
      .catch((err) => {
        message.error(err.response.data.message ?? "Не удалось создать урок");
      })
      .finally(async () => {
        await swrRef.current?.mutate();
      });
  };

  const onCreateLongread = async () => {
    return axios
      .post<OrionRestCreateResponse<Longread>>("/api/longreads", {
        course_id: courseId,
        name: "Лонгрид",
      })
      .then(async ({ data: { data: longread } }) => {
        await reBindContentToTree("longread", longread);
      })
      .catch((err) => {
        message.error(
          err.response.data.message ?? "Не удалось создать лонгрид",
        );
      })
      .finally(async () => {
        await swrRef.current?.mutate();
      });
  };

  const onCreateTest = async () => {
    return axios
      .post<OrionRestCreateResponse<Test>>("/api/tests", {
        course_id: courseId,
        name: "Тест",
        type: "intermediate",
        is_shuffles_questions: false,
        is_questions_database: false,
        is_limits_testing_time: false,
        is_limits_testing_attempts: false,
        show_results_format: "hidden",
        success_criteria: 100,
        questions: [],
      } as Test)
      .then(async ({ data: { data: test } }) => {
        await reBindContentToTree("test", test);
      })
      .catch((err) => {
        message.error(err.response.data.message ?? "Не удалось создать тест");
      })
      .finally(async () => {
        await swrRef.current?.mutate();
      });
  };

  const onCreateScorm = async () => {
    return axios
      .post<OrionRestCreateResponse<Scorm>>("/api/scorms", {
        course_id: courseId,
        name: "SCORM",
      } as Partial<Scorm>)
      .then(async ({ data: { data: scorm } }) => {
        await reBindContentToTree("scorm", scorm);
      })
      .catch((err) => {
        console.error(err);
        message.error(err.response.data.message ?? "Не удалось создать SCORM");
      })
      .finally(async () => {
        await swrRef.current?.mutate();
      });
  };

  const onDrop: TreeProps<DataNode>["onDrop"] = async (info) => {
    setIsUpdating(true);
    const dragNode = info.dragNode.meta.treeNode;
    const data: Partial<TreeNode> = {};

    if (info.dropToGap) {
      // вставить после node
      data.parent_tree_node_id = info.node.meta.treeNode.parent_tree_node_id;
      data.order_index = info.node.meta.treeNode.order_index + 1;
    } else {
      // вставить внутрь node
      data.parent_tree_node_id = info.node.meta.treeNode.id;
      data.order_index = info.node.dragOverGapBottom ? info.dropPosition : -1;
    }

    const parentNode = await axios
      .request<OrionRestShowResponse<TreeNode>>({
        method: "GET",
        url: `/api/tree-nodes/${data.parent_tree_node_id}`,
      })
      .then((res) => res.data.data);

    const someToNestingEnum: { [key: string]: string[] } = {
      section: ["course"],
      lesson: ["course", "section"],
      test: ["course", "section"],
      scorm: ["course", "section"],
      longread: ["course", "section"],
    };

    const itemEnum: { [key: string]: string } = {
      section: "раздел",
      lesson: "урок",
      test: "тест",
      scorm: "SCORM",
      longread: "лонгрид",
    };

    if (
      !someToNestingEnum[dragNode.is_an_type].includes(parentNode.is_an_type)
    ) {
      const nesting = itemEnum[dragNode.is_an_type] ?? dragNode.is_an_type;
      const target = itemEnum[parentNode.is_an_type] ?? parentNode.is_an_type;
      message.error(`Невозможно вложить ${nesting} в ${target}`);
      setIsUpdating(false);
      return Promise.resolve();
    }

    return axios
      .put(`/api/tree-nodes/${dragNode.id}`, data)
      .catch((err) => {
        setIsUpdating(false);
        message.error(err.response.data.message ?? "Не обновить содержание");
        throw err;
      })
      .then(async () => {
        await swrRef.current?.mutate();
        setIsUpdating(false);
        message.success("Содержание обновлено");
      });
  };

  const onSelect: TreeProps<DataNode>["onSelect"] = (selectedKey) => {
    if (selectedKey.length > 1) throw new Error("selectedKey.length > 1");

    setSelected(selectedKey[0]);
  };

  const EmbedEditForm = useCallback(() => {
    useEffect(() => {
      if (selected && typeof selected === "number")
        swrRef.current?.scrollTo(selected);
    }, []);
    if (!selected || !selectedTreeNode) {
      return (
        <Typography.Text type={"secondary"}>
          Выберите элемент содержания для редактирования
        </Typography.Text>
      );
    }

    if (selectedTreeNode.is_an_type === "test") {
      return (
        <TestForm
          type={"update"}
          testId={selectedTreeNode.is_an_id}
          onAfterUpdate={async () => swrRef.current?.mutate()}
          onAfterDelete={() => {
            swrRef.current?.mutate();
            setSelected(undefined);
          }}
        />
      );
    }

    const Form =
      formsGlob[`/src/entities/${selectedTreeNode.is_an_type}/ui/form.tsx`];

    const rest: RestProps<Lesson> = {
      type: "update",
      recordKey: selectedTreeNode.is_an_id,
      onAfterUpdate: () => swrRef.current?.mutate(),
    };

    return (
      <Form
        rest={rest}
        id={selectedTreeNode.is_an_id}
        onDelete={() => {
          swrRef.current?.mutate();
          setSelected(undefined);
        }}
      />
    );
  }, [selectedTreeNode]);

  return (
    <Row
      gutter={[token.marginMD, token.marginMD]}
      align={"stretch"}
      style={{ height: "100%" }}
    >
      <Col span={6} style={{ height: "100%" }}>
        <Flex vertical justify={"space-between"} style={{ height: "100%" }}>
          <AntdFlex vertical style={{ height: "100%" }}>
            <CourseEmbedsTree
              id={courseId}
              draggable
              selectedTreeNode={selectedTreeNode}
              onDrop={onDrop}
              disabled={isUpdating}
              selectedKeys={[selected]}
              expandedKeys={expandendKeys}
              onExpand={onExpand}
              blockNode
              onSelect={onSelect}
              swrRef={swrRef}
              {...props}
            />
          </AntdFlex>
          <Dropdown
            arrow
            placement={"topCenter"}
            menu={{
              items: [
                { key: "section", label: "Раздел", onClick: onCreateSection },
                { key: "lesson", label: "Урок", onClick: onCreateLesson },
                {
                  key: "longread",
                  label: "Лонгрид",
                  onClick: onCreateLongread,
                },
                { key: "test", label: "Тест", onClick: onCreateTest },
                { key: "scorm", label: "SCORM", onClick: onCreateScorm },
              ],
            }}
          >
            {member.permissions.includes("course:update") ? (
              <Button style={{ width: "100%" }}>Добавить материал</Button>
            ) : (
              <></>
            )}
          </Dropdown>
        </Flex>
      </Col>
      <Col span={18} style={{ height: "100%" }}>
        <Flex
          vertical
          style={{ padding: token.paddingMD, overflow: "auto", height: "100%" }}
        >
          <EmbedEditForm />
        </Flex>
      </Col>
    </Row>
  );
};

export default CourseEmbedsTreeEditor;
export type { CourseEmbedsTreeEditorProps };
