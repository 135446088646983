import React from "react";
import {
  ProDescriptions,
  ProDescriptionsProps,
} from "@ant-design/pro-components";
import { Lesson } from "@/models";
import { Typography } from "antd";

type BaseLessonDescriptionsProps = ProDescriptionsProps<Lesson>;
type RecordType = BaseLessonDescriptionsProps &
  Required<Pick<BaseLessonDescriptionsProps, "dataSource">>;
type LessonDescriptionsProps = ProDescriptionsProps<RecordType>;

const LessonDescriptions: React.FC<LessonDescriptionsProps> = ({
  ...props
}) => {
  return (
    <ProDescriptions<RecordType> {...props}>
      <ProDescriptions.Item
        dataIndex={"name"}
        span={24}
        render={(content) => (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {content}
          </Typography.Title>
        )}
      />
      <ProDescriptions.Item
        dataIndex={"description"}
        span={24}
        render={(content) => <Typography.Text>{content}</Typography.Text>}
      />
    </ProDescriptions>
  );
};
export default LessonDescriptions;
export type { LessonDescriptionsProps };
