import React from "react";
import { Member as BaseMember, Permission } from "@/models";

export type MemberContextValue = BaseMember & {
  refresh: () => void;
  permissions: Permission["key"][];
};

const MeContext = React.createContext<MemberContextValue | undefined>(
  undefined,
);

export default MeContext;
