import React from "react";
import { Button, Result } from "antd";
import AuthButton from "@/shared/components/auth-button";
import { useNavigate } from "react-router-dom";

const LoginRequiredPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      title="Требуется авторизация"
      extra={[
        <AuthButton key={"logout"} />,
        <Button key="home" onClick={() => navigate("/")}>
          Домой
        </Button>,
      ]}
    />
  );
};

export default LoginRequiredPage;
