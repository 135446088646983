import React, { useEffect, useRef, useState } from "react";
import { ServiceAccount } from "@/models";
import axios from "@/axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { ActionType } from "@ant-design/pro-table/es/typing";
import { AxiosRequestConfig } from "axios";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import ServiceAccountDeleteButton from "@/entities/service-account/ui/delete-button";
import { RestCallbacks } from "@/shared/rest/lib/types";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import typeEnumValue from "@/entities/service-account/lib/type-enum-value.ts";
import ResetSecretsButton from "./reset-secrets-button";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import ServiceAccountModalForm from "@/entities/service-account/ui/modal-form.tsx";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import { Grid } from "antd";
import {
  ProFormDateTimeRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

type Data = ServiceAccount;

type Params = Partial<ServiceAccount>;

type TableProps = ProTableProps<Data, Params>;

type Props = TableProps & {
  rest?: RestCallbacks<ServiceAccount>;
};

const ServiceAccountsTable: React.FC<Props> = ({ rest, ...props }) => {
  const action = useRef<ActionType>();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const request: TableProps["request"] = async (params, sort, filter) => {
    const actionConfig: AxiosRequestConfig = {
      method: "POST",
      url: "/api/service-accounts/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    const { data } = await axios.request<
      OrionRestIndexResponse<ServiceAccount>
    >(actionConfig);
    return {
      data: data.data,
      success: true,
      total: data.meta.total,
    };
  };

  const toolBarRender: TableProps["toolBarRender"] = (
    _,
    { selectedRowKeys },
  ) => {
    if (!selectedRowKeys) throw new Error("selectedRowKeys is undefined");

    const toRestoreRecordKey =
      selectedRowKeys?.length === 1 ? Number(selectedRowKeys?.[0]) : undefined;
    return [
      member.permissions.includes("service_account:delete") && (
        <ServiceAccountDeleteButton
          key={"delete"}
          rest={{
            recordKey: selectedRowKeys.map(Number),
            onAfterDelete: () => {
              action.current?.reload();
              action.current?.clearSelected?.();
              rest?.onAfterDelete?.();
            },
          }}
        />
      ),
      member.permissions.includes("service_account:update") && (
        <ResetSecretsButton
          key={"reset-password"}
          recordKey={toRestoreRecordKey}
        />
      ),
      member.permissions.includes("service_account:update") && (
        <>
          <ServiceAccountModalForm
            key={`update-${toRestoreRecordKey}`}
            rest={{
              type: "update",
              recordKey: toRestoreRecordKey,
              onAfterUpdate: (record) => {
                action.current?.reload();
                rest?.onAfterUpdate?.(record);
              },
            }}
          />
        </>
      ),
      member.permissions.includes("service_account:create") && (
        <ServiceAccountModalForm
          key={"create"}
          rest={{
            type: "create",
            onAfterCreate: (record) => {
              action.current?.reload();
              rest?.onAfterCreate?.(record);
            },
          }}
        />
      ),
    ];
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    rowSelection: {},
    options: false,
    style: {
      width: "calc(100vw - 200px - 32px",
      maxWidth: "calc(1920px - 200px - 32px",
    },
    scroll: { y: parentHeight - (screens.xl ? 162 : 172) },
    pagination: { showSizeChanger: true },
    toolBarRender,
    request,
    actionRef: action,
    columns: [
      {
        title: "Название",
        dataIndex: "name",
        sorter: true,
      },
      {
        title: "Тип",
        dataIndex: "type",
        valueEnum: typeEnumValue,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Почта для системных писем",
        formItemProps: {
          tooltip: false,
        },
        tooltip: true,
        dataIndex: "email",
        hideInSearch: true,
      },
      {
        title: () => "Дата время последнего использования",
        formItemProps: {
          tooltip: false,
        },
        tooltip: true,
        dataIndex: "last_used_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        hideInSearch: true,
        sorter: true,
      },
      {
        title: () => "Время создания",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          name: ["range", "created_at"],
          tooltip: false,
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  return (
    <div ref={ref} style={{ height: "calc(100% - 30px)", width: "100%" }}>
      <ProTable<Data, Params>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </div>
  );
};

export default ServiceAccountsTable;
