import { useEffect, useRef, useState } from "react";

const useParentHeight = (mode: "table" | "default" = "default") => {
  const ref = useRef<HTMLDivElement>(null);
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setParentHeight(
          ref.current.offsetHeight -
            (mode === "table" ?   ref.current.getElementsByClassName("ant-pro-table-search-query-filter")[0]
              ?.clientHeight + 16 ?? 0 : 0),
        );
      }
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(mode === "table" ? ref.current.getElementsByClassName("ant-pro-card")[1] ?? ref.current : ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(mode === "table" ? ref.current.getElementsByClassName("ant-pro-card")[1] ?? ref.current : ref.current);
      }
    };
  }, [ref.current]);

  return { ref, parentHeight };
};

export default useParentHeight;
