import React, { useState } from "react";
import { MemberOrgStructureAssignmentsHistoryRecord } from "@/models.ts";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import axios from "@/axios.ts";
import { dateSTime } from "@/shared/dayjs/lib/formats.tsx";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest.tsx";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import { useTranslation } from "react-i18next";

type Data = MemberOrgStructureAssignmentsHistoryRecord & {
  filters?: any[];
};

type Params = Partial<Data>;

type TableProps = ProTableProps<Data, Params>;

const CompanyPersonnelChangesTable: React.FC<TableProps> = ({ ...props }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);

  const request: ProTableProps<
    MemberOrgStructureAssignmentsHistoryRecord,
    any
  >["request"] = async (params, sort, filter) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: "/api/m-o-s-a-history-records/search",
      ...axiosConfigAdapter(params, sort, filter),
    };

    if (params.filters) {
      config.data.filters.push(...params.filters);
    }
    config.data.includes.push(
      { relation: "user" },
      { relation: "org_structure_assignment" },
      { relation: "org_structure_assignment.position" },
      { relation: "org_structure_assignment.department" },
      { relation: "org_structure_assignment.member" },
    );
    return axios
      .request<
        OrionRestIndexResponse<MemberOrgStructureAssignmentsHistoryRecord>
      >(config)
      .then((res) => {
        return {
          data: res.data.data,
          success: true,
          total: res.data.meta.total,
        };
      })
      .catch((error) => {
        setError(error);
        throw error;
      });
  };

  const defaultProps: TableProps = {
    rowKey: "id",
    search: false,
    options: false,
    toolBarRender: false,
    pagination: {
      defaultPageSize: 20,
    },
    request: request,
    style: { width: "100%" },
    columns: [
      {
        title: "Изменивший информацию",
        dataIndex: ["user", "full_name"],
        sorter: true,
      },
      {
        title: "ФИО",
        dataIndex: ["member_full_name"],
        sorter: true,
      },
      {
        dataIndex: "old_department",
        title: () => t("Старое подразделение"),
        sorter: true,
      },
      {
        dataIndex: "new_department",
        title: () => t("Новое подразделение"),
        sorter: true,
      },
      {
        dataIndex: "old_position",
        title: () => t("Старая должность"),
        sorter: true,
      },
      {
        dataIndex: "new_position",
        title: () => t("Новая должность"),
        sorter: true,
      },
      {
        title: "Дата изменения",
        dataIndex: "updated_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
      },
    ],
  };

  if (error) throw error;

  return (
    <>
      <ProTable<MemberOrgStructureAssignmentsHistoryRecord>
        {...deepmergeProTableProps(defaultProps as any, props as any)}
      />
    </>
  );
};

export default CompanyPersonnelChangesTable;
