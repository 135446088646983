import React from "react";
import { ProFormSelect, ProFormSelectProps } from "@ant-design/pro-components";
import axios from "@/axios";
import { Role } from "@/models";
import { message, Spin, Tag, Typography } from "antd";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import useSWR from "swr";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import useMe from "../me/lib/use";

type RolesSearchSelectProps = Partial<ProFormSelectProps> & {
  filteredId?: number;
  isDefaultEmployee?: boolean;
};

const RolesSearchSelect: React.FC<RolesSearchSelectProps> = ({
  filteredId,
  isDefaultEmployee,
  ...selectProps
}) => {
  const member = useMe();
  const { data, isLoading } = useSWR(
    member.permissions.includes("role:view") ? "/api/roles/search" : null,
    async (url) => {
      return await axios
        .post<OrionRestIndexResponse<Role>>(url)
        .then((res) => {
          const data: Role[] = [];
          res.data.data.forEach((role) => {
            if (filteredId === role.id) return;
            data.push(role);
          });
          return data;
        })
        .catch((error) => {
          message.error(
            error.response.data.message ?? "Ошибка получения ролей",
          );
        });
    },
  );
  if (isLoading) return <Spin />;

  return (
    <ProFormSelect
      options={data?.map((role) => ({
        label: (
          <Tag color={role.color} bordered>
            <Typography.Text style={{ color: invertColor(role.color) }}>
              {role.name}
            </Typography.Text>
          </Tag>
        ),
        value: role.id,
      }))}
      showSearch
      label={"Роль"}
      initialValue={
        isDefaultEmployee && data?.find((role) => role.is_default)?.id
      }
      {...selectProps}
    />
  );
};

export default RolesSearchSelect;
export type { RolesSearchSelectProps };
