import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import Spin from "@/shared/components/spin.tsx";

type ContextAuthRefreshProps = {
  children: React.ReactNode;
};

const ContextAuthRefresh: React.FC<ContextAuthRefreshProps> = ({
  children,
}) => {
  const auth = useAuth();
  const [isTriedRefresh, setIsTriedRefresh] = React.useState(false);

  useEffect(() => {
    console.debug("ContextAuthRefresh", {
      isTriedRefresh,
      auth,
      "auth.user": auth.user,
      "auth.user?.expired": auth.user?.expired,
    });
    if (isTriedRefresh || auth.isLoading) return;

    (async () => {
      if (!auth.user) return Promise.resolve();

      console.debug("ContextAuthRefresh", "User exists trying refresh");
      return auth.signinSilent().then((user) => {
        console.debug("ContextAuthRefresh", { user });
        if (user) {
          console.debug("ContextAuthRefresh", "User is refreshed");
          return Promise.resolve();
        }
        console.debug("ContextAuthRefresh", "User is not refreshed, removing");
        return auth.removeUser();
      });
    })().finally(() => {
      setIsTriedRefresh(true);
    });
  }, [auth.isLoading]);

  if (!isTriedRefresh) return <Spin />;

  return <>{children}</>;
};

export default ContextAuthRefresh;
export type { ContextAuthRefreshProps };
