import React from "react";
import LicenseDescriptions from "@/entities/license/ui/descriptions.tsx";
import useSpace from "@/entities/space/lib/use.tsx";
import PageContainer from "@/shared/ant-design/page-container/ui";
import ImportLicenseForm from "@/entities/license/ui/import-button.tsx";
import useMode from "@/entities/mode/lib/use.tsx";
import { Flex } from "antd";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const Page: React.FC = () => {
  const space = useSpace();
  const mode = useMode();

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <PageContainer>
        <Flex style={{ width: "100%", height: "100%", overflow: "auto" }}>
          <LicenseDescriptions dataSource={space.license!} />
          {mode === "self-hosted" && (
            <ImportLicenseForm onAfterImport={space.refresh} />
          )}
        </Flex>
      </PageContainer>
    </Flex>
  );
};

export default Page;
