import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import axios from "@/axios";
import { Spin } from "antd";
import { PoolParticipant } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";

const BreadcrumbItem: React.FC = () => {
  const { pool_participant_id } = useParams();
  const { data, error, isLoading } = useSWR(
    `/api/pool-participants/${pool_participant_id!}?include=pool,pool.course`,
    async (url) => {
      return axios
        .get<OrionRestShowResponse<PoolParticipant>>(url)
        .then((res) => res.data.data);
    },
  );

  if (error) throw error;
  if (isLoading) return <Spin />;
  if (!data) throw new Error("Pool participant not found");

  return <>{data.pool!.course!.name}</>;
};

export default BreadcrumbItem;
