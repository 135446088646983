import React, { Ref, useEffect, useImperativeHandle } from "react";
import { Tree, TreeProps, TreeDataNode as BaseTreeDataNode, Space } from "antd";
import axios from "@/axios";
import useSWR from "swr";
import Spin from "@/shared/components/spin";
import TreeNodeIcon from "@/entities/tree-node/ui/icon";
import { EducationMark, PoolParticipant, TreeNode } from "@/models";
import { OrionRestShowResponse } from "@/shared/types/orion-rest";
import { CheckOutlined } from "@ant-design/icons";
import NotFoundPage from "@/shared/pages/not-found";

export const dataMapper = (
  treeNode: any,
  educatiomMark: EducationMark[],
): TreeDataNode => {
  const EducationMarkValueIcon: React.FC<{ value?: string }> = ({ value }) => {
    switch (value) {
      case "completed":
        return <CheckOutlined />;
      case "not_completed":
      default:
        return null;
    }
  };

  const educationMark = educatiomMark.find(
    (mark) =>
      mark.material_type === treeNode.is_an_type &&
      mark.material_id === treeNode.is_an_id,
  );

  return {
    key: treeNode.id,
    title: (
      <Space style={{ alignItems: "start" }}>
        <TreeNodeIcon type={treeNode.is_an_type} />
        {treeNode.is_an.name}
        <EducationMarkValueIcon value={educationMark?.value} />
      </Space>
    ),
    children: treeNode.tree.map((node: any) => dataMapper(node, educatiomMark)),
    meta: { treeNode: treeNode, educationMark },
    disabled: educationMark?.is_view_available === false,
  };
};

export const flattenTree = (nodes: TreeDataNode[]) => {
  let flatList = [] as TreeDataNode[];

  nodes.forEach((node) => {
    flatList.push(node);

    if (node.children && node.children.length > 0) {
      flatList = flatList.concat(flattenTree(node.children as TreeDataNode[]));
    }
  });

  return flatList;
};

type TreeDataNode = BaseTreeDataNode & {
  meta: {
    treeNode: TreeNode;
    educationMark?: EducationMark;
  };
};

type SWRHook = {
  mutate: () => Promise<any>;
};

type CourseTreeProps = Partial<TreeProps<TreeDataNode>> & {
  swrRef?: Ref<SWRHook>;
  poolParticipantId: string | undefined;
  onNextEducationIsViewAvailable?: (isViewAvialable: boolean) => void;
  indexCurrentTree: number;
};

const CourseTree: React.FC<CourseTreeProps> = ({
  swrRef,
  poolParticipantId,
  onNextEducationIsViewAvailable,
  indexCurrentTree,
  ...treeProps
}) => {
  const { data, isLoading, error, mutate } = useSWR(
    [
      `/api/pool-participants/${poolParticipantId}`,
      {
        include: [
          "pool.course.tree_node",
          "pool.course.tree_node.tree",
          "education_marks",
        ].join(","),
      },
    ],
    ([url, params]) =>
      axios
        .get<OrionRestShowResponse<PoolParticipant>>(url, { params })
        .then((res) => res.data.data),
  );

  const treeData: TreeProps<TreeDataNode>["treeData"] =
    // @ts-ignore
    data?.pool!.course!.tree_node?.tree.map((node) =>
      dataMapper(node, data.education_marks!),
    ) ?? [];

  const flatTreeData = flattenTree(treeData || []);

  useEffect(() => {
    if (data) {
      onNextEducationIsViewAvailable?.(
        !(
          flatTreeData[indexCurrentTree + 1]?.meta.educationMark ===
            undefined ||
          flatTreeData[indexCurrentTree + 1].meta.educationMark!
            .is_view_available
        ),
      );
    }
  }, [data]);

  useImperativeHandle<any, any>(swrRef, () => ({
    mutate,
  }));

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("Data is undefined");
  if (data.pool?.status !== "started") return <NotFoundPage />;
  return (
    <Tree<TreeDataNode>
      showIcon
      defaultExpandAll
      treeData={treeData}
      {...treeProps}
    />
  );
};

export default CourseTree;
export type { CourseTreeProps, SWRHook, TreeDataNode };
