import React from "react";
import { FloatButton } from "antd";
import { BugOutlined } from "@ant-design/icons";
import useClient from "@/entities/client/lib/use.tsx";
import { useAuth } from "react-oidc-context";
import SentryUserFeedbackModal from "@/shared/sentry/ui/user-feedback-modal";

const LayoutSentryUserFeedbackModal: React.FC = () => {
  const client = useClient();
  const auth = useAuth();

  return (
    <>
      {(client.type !== "space" || !auth.isAuthenticated) && (
        <SentryUserFeedbackModal
          modal
          trigger={<FloatButton icon={<BugOutlined />} />}
        />
      )}
    </>
  );
};

export default LayoutSentryUserFeedbackModal;
