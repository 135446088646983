import React from "react";
import useSWR from "swr";
import axios from "@/axios";
import Spin from "@/shared/components/spin";
import AccessDeniedResult from "@/entities/me/ui/access-denied-result";
import MeContext, { MemberContextValue } from "@/entities/me/lib/context";
import useTheme from "@/shared/theme/lib/use";

type Props = {
  children: React.ReactNode;
};

const MeContextProvider: React.FC<Props> = ({ children }) => {
  const themeAlgorithm = useTheme();

  const { data, isLoading, error, mutate } = useSWR(
    "/api/me",
    (url) =>
      axios.get<MemberContextValue>(url).then((res) => {
        themeAlgorithm.set(res.data.theme);
        return res.data;
      }),
    { refreshInterval: 60 * 1000 },
  );

  if (isLoading) return <Spin />;
  if (error) throw error;
  if (!data) throw new Error("No data");
  if (data.status !== "active") return <AccessDeniedResult />;

  return (
    <MeContext.Provider
      value={{
        ...data,
        refresh: () => {
          mutate();
        },
      }}
    >
      {children}
    </MeContext.Provider>
  );
};

export default MeContextProvider;
