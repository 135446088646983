import React, { useState } from "react";
import { Department, Member, Position, Role } from "@/models";
import axios from "@/axios";
import MemberForm from "@/entities/member/ui/form";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import MemberEducationPurposeModalForm from "@/features/member/education-purpose/ui/modal-form";
import deepmergeProTableProps from "@/shared/ant-design-pro-components/table/lib/deepmerge-props";
import ProTable, {
  ProTableProps,
} from "@/shared/ant-design-pro-components/table/ui";
import { AxiosRequestConfig } from "axios";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Space, Tag, Typography } from "antd";
import useSpace from "@/entities/space/lib/use";
import MemberResetPasswordButton from "@/features/member/reset-password/ui/button";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import { statusValueEnum } from "@/entities/member/lib/status-value-enum.tsx";
import ExportButton from "@/entities/export/ui/button";
import ImportButton from "@/entities/import/ui/button";
import useParentHeight from "@/shared/hooks/use-parent-height";
import useMe from "@/entities/me/lib/use";
import { useTranslation } from "react-i18next";
import { invertColor } from "@/shared/helpers/invert-hex-color";
import {
  ProFormDateTimeRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";

type Params = {
  role: string;
};

type MembersTableProps = ProTableProps<Member, Params>;

const MembersTable: React.FC<MembersTableProps> = ({ ...props }) => {
  const { t } = useTranslation();

  const space = useSpace();
  const member = useMe();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { parentHeight, ref } = useParentHeight("table");

  const defaultProps: MembersTableProps = {
    rowKey: "id",
    pagination: { showSizeChanger: true },
    options: false,
    toolBarRender: (action, { selectedRowKeys }) => {
      if (action === undefined) {
        throw new Error("action is undefined");
      }

      if (selectedRowKeys === undefined) {
        throw new Error("selectedRowKeys is undefined");
      }

      return [
        member.permissions.includes("member:reset_password") && (
          <MemberResetPasswordButton
            key="reset-password"
            membersIds={selectedRowKeys.map(Number)}
            onAfterFinish={() => action.reload()}
          />
        ),
        member.permissions.includes("pool_participant:create") && (
          <MemberEducationPurposeModalForm
            key="education-purpose"
            membersIds={selectedRowKeys.map(Number)}
          />
        ),
        member.permissions.includes("export:export_members") && (
          <ExportButton
            metaFields={{ members_ids: selectedRowKeys.map(Number) }}
            type="export_members"
          />
        ),
        member.permissions.includes("import:members") && (
          <ImportButton
            key="import"
            type="members"
            onAfterFinish={() => action.reload()}
          />
        ),
        member.permissions.includes("member:create") && (
          <MemberForm
            rest={{
              type: "create",
              onAfterCreate: () => action.reload(),
            }}
            modal
          />
        ),
      ];
    },
    request: async (
      params,
      sort,
      filter: Record<string, (string | number | null)[] | null>,
    ) => {
      if (sort === undefined || Object.keys(sort).length === 0) {
        sort = { created_at: "descend" };
      }

      const {
        ["org_structure_assignments,department_id"]: departmentIds,
        ["org_structure_assignments,position_id"]: positionIds,
        ["groups,id"]: groupsIds,
        ...filters
      } = filter;

      const config: AxiosRequestConfig = {
        method: "POST",
        url: `/api/members/search`,
        ...axiosConfigAdapter(params, sort, filters),
      };

      if (departmentIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInOrgStructureAssignments",
          parameters: [
            departmentIds.includes(null),
            "department_id",
            departmentIds,
          ],
        });
      }

      if (positionIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInOrgStructureAssignments",
          parameters: [positionIds.includes(null), "position_id", positionIds],
        });
      }

      if (groupsIds) {
        config.data.scopes.push({
          name: "orWhereDoesntHaveInGroups",
          parameters: [groupsIds.includes(null), groupsIds],
        });
      }

      config.data.sort.push({
        field: "id",
        direction: "desc",
      });

      config.data.includes.push(
        { relation: "org_structure_assignments.position" },
        { relation: "org_structure_assignments.department" },
        { relation: "groups" },
        { relation: "role" },
      );
      const data = await axios
        .request<OrionRestIndexResponse<Member>>(config)
        .then((res) => res.data);

      return {
        data: data.data,
        success: true,
        total: data.meta.total,
      };
    },
    rowSelection: {
      preserveSelectedRowKeys: true,
      selectedRowKeys,
      onChange: (keys) => {
        setSelectedRowKeys(keys);
      },
    },
    scroll: {
      y: parentHeight - (selectedRowKeys.length ? 254 : 192),
    },
    columns: [
      {
        title: "ФИО",
        dataIndex: "full_name",
        sorter: true,
      },
      {
        title: "Роль",
        dataIndex: "role_id",
        render: (_, { role }) => (
          <Tag color={role.color} bordered>
            <Typography.Text
              ellipsis={{ tooltip: true }}
              style={{ color: invertColor(role.color), maxWidth: "120px" }}
            >
              {role.name}
            </Typography.Text>
          </Tag>
        ),
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/roles/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Role>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((role) => ({
                  label: role.name,
                  value: role.id,
                })),
              );
          },
          filterSearch: true,
          withNullFilter: false,
        }),
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: "Почта",
        dataIndex: "email",
        hideInTable: !space.authentication_types.includes("email+password"),
        hideInSearch: !space.authentication_types.includes("email+password"),
        copyable: true,
      },
      {
        title: "Логин",
        dataIndex: "username",
        hideInTable: !space.authentication_types.includes("username+password"),
        hideInSearch: !space.authentication_types.includes("username+password"),
        copyable: true,
      },
      {
        title: "Статус",
        dataIndex: "status",
        valueType: "select",
        valueEnum: statusValueEnum,
        defaultFilteredValue: ["active"],
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => t("Подразделение"),
        formItemProps: {
          tooltip: false,
        },
        tooltip: true,
        dataIndex: ["org_structure_assignments", "department_id"],
        render: (_, { org_structure_assignments }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-department`}>
                  {el.department?.name ? el.department?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/departments/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Department>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: t("Должность"),
        dataIndex: ["org_structure_assignments", "position_id"],
        render: (_, { org_structure_assignments }) => {
          if (
            org_structure_assignments &&
            org_structure_assignments.length === 0
          ) {
            return "-";
          }
          return (
            <Space direction="vertical">
              {org_structure_assignments?.map((el) => (
                <Typography.Text key={`${el.id}-position`}>
                  {el.position?.name ? el.position?.name : "-"}
                </Typography.Text>
              ))}
            </Space>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/positions/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((course) => ({
                  label: course.name,
                  value: course.id,
                })),
              );
          },
          filterSearch: true,
        }),
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: "Группa",
        dataIndex: ["groups", "id"],
        render: (_, { groups }) => {
          return (
            <Typography.Text ellipsis={{ tooltip: true }}>
              {groups?.map((el) => el.name).join(", ")}
            </Typography.Text>
          );
        },
        ...useDynamicColumnFilters({
          request: async (params) => {
            const config: AxiosRequestConfig = {
              method: "POST",
              url: "/api/groups/search",
              ...axiosConfigAdapter(),
            };

            if (params.search) {
              config.data.filters.push({
                field: "name",
                operator: "ilike",
                value: `%${params.search}%`,
              });
            }

            return axios
              .request<OrionRestIndexResponse<Position>>(config)
              .then((res) => res.data)
              .then((res) =>
                res.data.map((group) => ({
                  label: group.name,
                  value: group.id,
                })),
              );
          },
          filterSearch: true,
        }),
        filters: false,
        renderFormItem(_, config) {
          return <ProFormSelect {...config} mode="multiple" />;
        },
      },
      {
        title: () => "Дата добавления",
        tooltip: true,
        dataIndex: "created_at",
        valueType: "dateTime",
        fieldProps: { format: dateSTime },
        sorter: true,
        defaultSortOrder: "descend",
        formItemProps: {
          normalize: normalizeDateRange,
          tooltip: false,
          name: ["range", "created_at"],
        },
        renderFormItem(_, config) {
          return (
            <ProFormDateTimeRangePicker
              {...config}
              fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
            />
          );
        },
      },
    ],
  };

  props = deepmergeProTableProps(defaultProps as any, props as any);

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      <ProTable<Member, Params> {...props} />
    </div>
  );
};

export default MembersTable;
export type { MembersTableProps };
