import React, { useRef } from "react";
import { Course } from "@/models";
import axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import { OrionRestIndexResponse } from "@/shared/types/orion-rest";
import { Flex, Grid, message, Modal, Space, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";
import ProTable from "@/shared/ant-design-pro-components/table/ui";
import axiosConfigAdapter from "@/shared/ant-design-to-orion-adapter/lib/axios-config";
import useDynamicColumnFilters from "@/shared/ant-design-pro-components/table/lib/use-dynamic-column-filters";
import { Test } from "@/entities/test/lib/model";
import { ActionType } from "@ant-design/pro-table/es/typing";
import Button from "@/shared/ant-design/button/ui/button";
import { testTypeValueEnum } from "@/entities/test/lib/type-value-enum";
import { dateSTime } from "@/shared/dayjs/lib/formats";
import useMe from "@/entities/me/lib/use";
import useParentHeight from "@/shared/hooks/use-parent-height";
import {
  ProFormDateTimeRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";
import { normalizeDateRange } from "@/entities/report/lib/normilized-data-range";
import ArchivalCourseIcon from "@/entities/course/ui/archival-icon";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [modal, modalHolder] = Modal.useModal();
  const member = useMe();
  const { parentHeight, ref } = useParentHeight("table");
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <>
      {modalHolder}
      <Flex
        ref={ref}
        vertical
        gap={8}
        style={{ width: "100%", height: "100%" }}
      >
        <AutoBreadcrumb />
        <ProTable<Test, Partial<Test>>
          actionRef={actionRef}
          hasQueryParams
          options={{
            setting: {
              draggable: false,
              showListItemOption: false,
            },
            reload: false,
            density: false,
          }}
          columnsState={{
            persistenceKey: "tests-table",
          }}
          rowKey={"id"}
          style={{
            width: "calc(100vw - 200px - 32px)",
            maxWidth: "calc(1920px - 200px - 32px)",
          }}
          scroll={{ y: parentHeight - (screens.xl ? 162 : 182) }}
          columns={[
            {
              title: "Курс",
              dataIndex: "course_id",
              sorter: "course.name",
              hideInSetting: true,
              disable: true,
              render: (dom, { course_id, course }) => {
                return (
                  <Link to={`/manage/courses/${course_id}`}>
                    {dom}{" "}
                    {course?.status === "archival" && <ArchivalCourseIcon />}
                  </Link>
                );
              },
              renderText: (_, { course }) => {
                return <>{course!.name}</>;
              },
              ...useDynamicColumnFilters({
                request: async (params) => {
                  const config: AxiosRequestConfig = {
                    method: "POST",
                    url: "/api/courses/search",
                    ...axiosConfigAdapter(),
                  };

                  if (params.search) {
                    config.data.filters.push({
                      field: "name",
                      operator: "ilike",
                      value: `%${params.search}%`,
                    });
                  }

                  return axios
                    .request<OrionRestIndexResponse<Course>>(config)
                    .then((response) => {
                      return response.data.data.map((course) => {
                        return { label: course.name, value: course.id };
                      });
                    });
                },
                filterSearch: true,
                withNullFilter: false,
              }),
              filters: false,
              renderFormItem(_, config) {
                return <ProFormSelect {...config} mode="multiple" />;
              },
            },
            {
              title: () => "Название теста",
              formItemProps: {
                tooltip: false,
              },
              tooltip: true,
              dataIndex: "name",
              render: (dom, { course_id, tree_node }) => {
                return (
                  <Link
                    to={{
                      pathname: `/manage/courses/${course_id}`,
                      search: `?tab=embeds&selected_tree_node=${tree_node!.id}`,
                    }}
                  >
                    {dom}
                  </Link>
                );
              },
              sorter: true,
              hideInSetting: true,
              disable: true,
            },
            {
              title: "Тип",
              dataIndex: "type",
              valueEnum: testTypeValueEnum,
              renderFormItem(_, config) {
                return <ProFormSelect {...config} mode="multiple" />;
              },
            },
            {
              title: "Заметка",
              dataIndex: "note",
              valueType: "textarea",
              hideInSearch: true,
              renderText: (text) => <Typography.Text>{text}</Typography.Text>,
            },
            {
              title: () => "Дата создания",
              tooltip: true,
              dataIndex: "created_at",
              valueType: "dateTime",
              fieldProps: { format: dateSTime },
              sorter: true,
              formItemProps: {
                normalize: normalizeDateRange,
                name: ["range", "created_at"],
                tooltip: false,
              },
              renderFormItem(_, config) {
                return (
                  <ProFormDateTimeRangePicker
                    {...config}
                    fieldProps={{ format: dateSTime, allowEmpty: [true, true] }}
                  />
                );
              },
              defaultSortOrder: "descend",
            },
            {
              title: "Действие",
              valueType: "option",
              hideInTable: !member.permissions.includes(
                "test_participant:view",
              ),
              render: (_, { id: test_id }) => {
                return (
                  <Link to={`/manage/tests/${test_id}/reports`}>Отчеты</Link>
                );
              },
              hideInSetting: true,
              disable: true,
            },
          ]}
          request={async (params, sort, filter) => {
            const config = {
              method: "POST",
              url: "/api/tests/search",
              ...axiosConfigAdapter(params, sort, filter),
            };

            config.data.includes.push({ relation: "course" });
            config.data.includes.push({ relation: "tree_node" });

            return axios
              .request<OrionRestIndexResponse<Test>>(config)
              .then((response) => {
                return {
                  data: response.data.data,
                  success: true,
                  total: response.data.meta.total,
                };
              });
          }}
          rowSelection={{}}
          toolBarRender={(action, { selectedRows, selectedRowKeys }) => {
            const onDelete = async () => {
              if (selectedRowKeys!.length === 0) {
                message.error("Не выбрано ни одного теста");
                return;
              }

              modal.confirm({
                title: "Вы уверены, что хотите удалить тесты?",
                content: "Данное действие нельзя отменить",
                okText: "Да",
                cancelText: "Нет",
                onOk: async () => {
                  return axios
                    .delete("/api/tests/batch", {
                      data: { resources: selectedRowKeys },
                    })
                    .then(() => {
                      action?.clearSelected?.();
                      actionRef.current?.reload();
                      message.success("Тесты успешно удалены");
                    })
                    .catch((error) => {
                      message.error("Ошибка при удалении тестов");
                      console.error(error);
                    });
                },
              });
            };

            const onUpdate = async () => {
              if (selectedRows!.length > 1) {
                message.error(
                  "Невозможно изменить, выбрано больше одной записи",
                );
                return;
              }
              if (selectedRows!.length === 0) {
                message.error(
                  "Невозможно изменить, не выбрано не одной записи",
                );
                return;
              }

              const course_id = selectedRows![0].course_id;

              navigate(`/manage/courses/${course_id}?tab=embeds`);
            };

            return [
              <Button
                key={"delete"}
                onClick={onDelete}
                disabled={selectedRowKeys!.length === 0}
                type={"primary"}
                danger
              >
                Удалить
              </Button>,
              <Button
                key={"update"}
                onClick={onUpdate}
                disabled={selectedRows!.length !== 1}
                type={"primary"}
              >
                Изменить
              </Button>,
            ];
          }}
        />
      </Flex>
    </>
  );
};

export default Page;
