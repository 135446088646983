import React from "react";
import {
  ProForm,
  ProFormDateTimePicker,
  ProFormProps,
  ProFormSelect,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { BroadcastMessages } from "@/models";
import { deepmerge } from "deepmerge-ts";
import axios from "@/axios";
import {
  OrionRestCreateResponse,
  OrionRestShowResponse,
  OrionRestUpdateResponse,
} from "@/shared/types/orion-rest";
import { message } from "antd";
import { RestProps } from "@/shared/rest/lib/types";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import { dateSTime, dateSTimeNormalize } from "@/shared/dayjs/lib/formats.tsx";
import valueEnum from "@/entities/broadcast-messages/lib/type-value-enum.tsx";

type BroadcastMessagesFormProps = ProFormProps<BroadcastMessages> & {
  rest: RestProps<BroadcastMessages>;
};

const BroadcastMessagesForm: React.FC<BroadcastMessagesFormProps> = ({
  rest,
  ...props
}) => {
  const [form] = ProForm.useForm<BroadcastMessages>(props.form);

  let defaultProps: Partial<typeof props> = {
    submitter: {
      resetButtonProps: { style: { display: "none" } },
    },
    preserve: false,
  };

  const overrideProps: Partial<typeof props> = {
    form,
  };

  /** REST Type Create */

  if (rest.type === "create") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Создать" } },
      initialValues: { type: "banner" },
    });
    defaultProps.onFinish = async (values) => {
      return await axios
        .post<OrionRestCreateResponse<BroadcastMessages>>(
          "/api/broadcast-messages",
          values,
        )
        .then((res) => {
          message.success("Баннер успешно создан");
          rest.onAfterCreate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          message.error(
            err.response.data.message ??
              "Ошибка при создании широковещательного сообщения",
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          } else {
            console.error(err);
          }

          return false;
        });
    };
  }

  /** REST Type Update */

  if (rest.type === "update") {
    defaultProps = deepmerge(defaultProps, {
      submitter: { searchConfig: { submitText: "Сохранить" } },
    });
    defaultProps.request = async () => {
      return axios
        .get<OrionRestShowResponse<BroadcastMessages>>(
          `/api/broadcast-messages/${rest.recordKey}`,
        )
        .then((res) => res.data.data);
    };
    defaultProps.onFinish = async (values) => {
      return axios
        .put<OrionRestUpdateResponse<BroadcastMessages>>(
          `/api/broadcast-messages/${rest.recordKey}`,
          values,
        )
        .then((res) => {
          message.success("Баннер успешно обновлён");
          rest.onAfterUpdate?.(res.data.data);

          return true;
        })
        .catch((err) => {
          console.error(err);
          const messageText = err.response.data.message ?? err.message;
          message.error(
            `Ошибка при обновлении широковещательного сообщения: ${messageText}`,
          );

          if (err.response.status === 422) {
            setValidationErrorsToFormFields(form, err.response.data.errors);
          }

          return false;
        });
    };
  }

  /** Pre Render */

  props = { ...deepmerge(defaultProps, props), ...overrideProps };

  /** Render */

  return (
    <ProForm<BroadcastMessages> {...props} grid style={{ width: "100%" }}>
      <ProFormSelect name={"type"} hidden />
      <ProFormSelect
        colProps={{ span: 24 }}
        label={"Тип"}
        name={"type"}
        valueEnum={valueEnum}
        rules={[{ required: true }]}
      />
      <ProFormTextArea
        name="text"
        label="Текст"
        rules={[{ required: true }]}
        colProps={{ span: 24 }}
      />
      <ProFormDateTimePicker
        colProps={{ span: 12 }}
        label={"Дата и время начала"}
        name={"starts_at"}
        rules={[{ required: true }]}
        fieldProps={{ format: dateSTime, style: { width: "100%" } }}
        normalize={dateSTimeNormalize}
      />
      <ProFormDateTimePicker
        colProps={{ span: 12 }}
        label={"Дата и время завершения"}
        name={"ends_at"}
        fieldProps={{ format: dateSTime, style: { width: "100%" } }}
        normalize={dateSTimeNormalize}
      />
    </ProForm>
  );
};
export default BroadcastMessagesForm;
export type { BroadcastMessagesFormProps };
