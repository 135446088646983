import { FeatureKey } from "@/entities/features/lib/model.ts";
import React from "react";
import FeaturesContext from "@/entities/features/lib/context.tsx";

const useFeatures = () => {
  const features = React.useContext(FeaturesContext);

  if (!features) throw new Error("FeaturesContext not provided");

  return {
    features,
    isEnabled: (key: FeatureKey) => features[key] ?? false,
  };
};

export default useFeatures;
