import React from "react";
import {
  ProForm,
  ProFormGroup,
  ProFormProps,
  ProFormSelect,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { Space } from "@/models";
import { Button, Flex, message, Space as AntdSpace, theme } from "antd";
import axios from "@/axios";
import { OrionRestUpdateResponse } from "@/shared/types/orion-rest";
import styled from "styled-components";
import { authenticationTypeEnum } from "@/entities/space/lib/model";
import useSpace from "@/entities/space/lib/use";
import PageContainer from "@/shared/ant-design/page-container/ui";
import { setValidationErrorsToFormFields } from "@/shared/orion-to-ant-design-adapter/lib/set-validation-errors-to-form-fields";
import useMe from "@/entities/me/lib/use";
import AutoBreadcrumb from "@/shared/auto-breadcrumb/ui/compoment";

const FormWrapper = styled.div`
  .space-form .ant-space {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .space-form {
    height: 100%;
  }
  height: 100%;
  .space-form .ant-space .ant-space-item {
    width: 100%;
  }
`;

const Page: React.FC = () => {
  const space = useSpace();
  const [form] = ProForm.useForm<Space>();
  const {
    token: { colorBgContainer, sizeMD, borderRadius },
  } = theme.useToken();
  const member = useMe();

  const request: ProFormProps<Space>["request"] = async () => {
    const space_id = await axios
      .get("/api/.well-known/space")
      .then((res) => res.data.id);

    return axios
      .get(`/api/spaces/${space_id}`)
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        throw reason;
      });
  };

  const onSave: ProFormProps<Space>["onFinish"] = async (values) => {
    const updatedValues = await axios
      .put<OrionRestUpdateResponse<Space>>(
        `/api/spaces/${form.getFieldsValue(true).id}`,
        values,
      )
      .then((res) => res.data.data)
      .catch((reason) => {
        message.error(reason.response.data.message ?? reason.message);
        if (reason.response.status === 422) {
          setValidationErrorsToFormFields(form, reason.response.data.errors);
        } else {
          console.error(reason);
        }
        throw reason;
      });

    message.success("Изменения успешно сохранены");

    space.refresh();

    // TODO: Fix this "any" type
    form.setFieldsValue(updatedValues as any);
  };

  return (
    <Flex vertical gap={8} style={{ width: "100%", height: "100%" }}>
      <AutoBreadcrumb />
      <PageContainer>
        <FormWrapper>
          <ProForm<Space>
            className={"space-form"}
            submitter={false}
            form={form}
            disabled={!member.permissions.includes("space:update")}
            onFinish={onSave}
            request={request}
          >
            <AntdSpace
              direction={"vertical"}
              style={{
                background: colorBgContainer,
                borderRadius,
              }}
            >
              <Flex justify={"end"} gap={16}>
                {member.permissions.includes("space:update") && (
                  <Button type={"primary"} onClick={form.submit}>
                    Сохранить
                  </Button>
                )}
              </Flex>

              <ProFormGroup title={"Настройки пространства"}>
                <ProFormSelect
                  name={"authentication_types"}
                  label={"Тип аутентификации"}
                  valueEnum={authenticationTypeEnum}
                  mode={"multiple"}
                  rules={[{ required: true, type: "array", min: 1 }]}
                  width={"lg"}
                />
                <ProFormSwitch
                  name={"is_allow_employee_edit_self_member"}
                  label={"Пользователи могут редактировать свои данные"}
                />
              </ProFormGroup>
            </AntdSpace>
          </ProForm>
        </FormWrapper>
      </PageContainer>
    </Flex>
  );
};

export default Page;
